// @flow

export type BookingRelevanceType =
    1 | 2 | 3 | 4;

export const BookingRelevance = {
    MY_ASSOCIATED_BOOKINGS: 1,
    MY_ASSOCIATED_INSTRUCTIONS: 2,
    PUBLIC: 3,
    PRIVATE: 4
}