// @flow

// $FlowFixMe unsafe type import from untyped module
import type { AxiosResponse, AxiosError } from 'axios';
import MutationEndpointConstants from "../constants/MutationEndpointConstants.js";
import * as baseMutation from "./baseMutation.js";

export const performMutation = async (
    email: string,
    password: string,
    isLongerSession: boolean,
    // $FlowFixMe
    onSuccess: (response: AxiosResponse) => void,
    // $FlowFixMe
    onFailure: (error: AxiosError) => void): Promise<AxiosResponse<any, any>> => {

    await baseMutation.performMutation(
        MutationEndpointConstants.LOGIN,
        { email, password, isLongerSession },
        onSuccess,
        onFailure,
    );
}