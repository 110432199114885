// @flow

import { Navigate, Outlet } from "react-router-dom";
import CookieConstants from "../constants/CookieConstants";
import { extractDataFromCookie } from "../utils/CookieUtils";

import { useCookies } from "react-cookie";
import React from "react";

const LoggedOutRoute = (): React.Node => {
    const [sessionDataCookie, _] = useCookies([CookieConstants.SESSION_DATA]);
    const parsedCookieData = extractDataFromCookie(sessionDataCookie?.session_data);

    return parsedCookieData ? <Navigate to="/" /> : <Outlet />;
};

export default LoggedOutRoute;