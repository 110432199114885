// @flow
import { toast } from "react-toastify";
import axios from "axios";
import { getToastMessage, getToastConfig } from "../errors/errorUtils.js";
import ErrorType from "../errors/ErrorType.js";
import QueryEndpointConstants from "../constants/QueryEndpointConstants.js";

export const getAllUserIdToNameMappingQuery = async (): Promise<{
    // userId => user name
    [string]: string
}> => {
    const response = await axios.get("/api" + QueryEndpointConstants.USERID_NAME_MAPPING).catch((error) => {
        toast.error(getToastMessage(error), getToastConfig(ErrorType.ALL_USERIDS_TO_NAME_MAPPING_QUERY));
    });
    return response;
};