// @flow

import React from "react";
import { Placeholder } from "react-bootstrap";

const ErrorFallback = (): React.Node => {
    return <>
        <p aria-hidden="true">
            <Placeholder xs={12} bg="danger" size="lg" animation="glow">
                <div className="text-danger">Something went wrong.</div>
            </Placeholder>
        </p>

    </>;
};

export default ErrorFallback;