// @flow
export type UserRoleType =
    'ADMIN' | 'INSTRUCTOR' | 'OPS' | 'SELF_HIRER' | 'PPL_STUDENT' | 'CPL_STUDENT' | 'SOCIAL_MEMBER';

export const UserRole = {
    ADMIN: 'ADMIN',
    INSTRUCTOR: 'INSTRUCTOR',
    OPS: 'OPS',
    SELF_HIRER: 'SELF_HIRER',
    PPL_STUDENT: 'PPL_STUDENT',
    CPL_STUDENT: 'CPL_STUDENT',
    SOCIAL_MEMBER: 'SOCIAL_MEMBER',
}