// @flow

import React from 'react';
import airplaneImg from "../img/airplane_icon.png"
import '../App.css';


const SpinningAirplane = (): React.Node =>
    <div className="d-flex justify-content-center" style={{ width: '100px', height: '100px' }}>
        <img src={airplaneImg} className="loopingAirplane" alt="looping airplane" />
    </div>

export default SpinningAirplane