// @flow

import type { ModType } from "../types/Mods.js";

import type { Node } from 'react';
import React from "react";
import type { UserRoleType } from "../types/UserRole.js";
import FeatureConfig from "../feature_config/FeatureConfig";
import type { ModNameType } from "../types/Mods";
import ModIntroCard from "./ModIntroCard.react.js";

import bookingsImg from '../img/airplane_takeoff.png';
import assetsImg from '../img/asset_icon.png';

const mapMod = (mod: ModType, userRole: UserRoleType): ?Node => {
    // if we can't find any entry for this mod in the enabled modes,
    // it must mean it's disabled
    if (FeatureConfig
        .getEnabledModsForApp()
        .filter((val) => val.name === mod.modName).length === 0) {
        return null;
    }

    const modsForUser = FeatureConfig.getModsForRoles()
        .find((val) => val.role === userRole);
    if (!modsForUser) {
        return null;
    }

    if (!modsForUser.mods.find((modName: ModNameType) => modName === mod.modName)) {
        return null;
    }

    if (!mod.hasUI) {
        return null;
    }

    switch (mod.modName) {
        case 'ASSET_MANAGEMENT':
            return <ModIntroCard
                img={assetsImg}
                title="Assets"
                content="View current assets"
                link={"/assets"}
                cardWidth="400px"
            />
        case 'BOOKINGS':
            return <ModIntroCard
                img={bookingsImg}
                title="Bookings"
                content="Book the aircraft or a lesson"
                link={"/bookings"}
                cardWidth="400px"
            />
        case 'COMPLIANCE':
            return null; // todo
        case 'MAINTENANCE':
            return null; // todo
        case 'MOBILE_TRACKING':
            return null; // todo
        case 'PAYMENTS':
            return null; // todo
        case 'SOCIAL_AREA':
            return null; // todo
        case 'STATS':
            return null; // todo
        case 'STUDENT_RECORDS':
            return null; // todo
        default:
            return null; // todo
    }
};

export default mapMod;