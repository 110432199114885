// @flow

import QueryConstants from "../constants/QueryConstants.js";
import AssetUtils from "../utils/AssetUtils.js";

import React from "react";
import { Suspense } from "react";
import { Container, Row, Spinner, Table } from "react-bootstrap";
import { useQuery } from "react-query";

type AssetData = {
    assetId: string,
    assetName: string,
    assetType: string
};


const SpecificAssetTypeTable = ({ assetType, assets }: { assetType: string, assets: Array<AssetData> }): React.Node => {
    // TODO add more options
    return <Container>
        <Row><h1>{AssetUtils.assetTypeToName(assetType) + " assets"}</h1></Row>
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Asset name</th>
                    <th>Asset id</th>
                </tr>
            </thead>
            <tbody>
                {
                    assets.map((asset, i) =>
                        <tr key={asset.assetId}>
                            <td width={'10%'}>{i + 1}</td>
                            <td width={'50%'}>{asset.assetName}</td>
                            <td width={'40%'}>{asset.assetId}</td>
                        </tr>)
                }
            </tbody>
        </Table>
    </Container >;
};


const SpecificAssetTypeTableWithLoader = ({ assetType, style }: { assetType: string, style: Object }): React.Node => {
    const [queryConstant, queryCallback] = AssetUtils.getQueryForType(assetType);
    const assetQuery = useQuery(queryConstant, queryCallback, { refetchOnMount: false, suspense: true, staleTime: QueryConstants.ASSET_DATA_STALE_TIME_MS });

    if (assetQuery.error) {
        return <Spinner variant="danger" />
    }

    if (assetQuery.isLoading) {
        return <Spinner variant="primary" />
    }

    return <Container style={style}>
        <SpecificAssetTypeTable assetType={assetType} assets={
            // $FlowFixMe, no idea why flow doesn't like this
            assetQuery.data} />
    </Container >;
}

const SpecificAssetTypeTableWithLoaderWithSuspense = ({ assetType, style }: { assetType: string, style: Object }): React.Node => {
    return <Suspense fallback={<Spinner animation="border" />}>
        <SpecificAssetTypeTableWithLoader assetType={assetType} style={style} />
    </Suspense>
}

const AssetTable = SpecificAssetTypeTableWithLoaderWithSuspense;

export default AssetTable;