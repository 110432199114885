// @flow

// $FlowFixMe unsafe type import from untyped module
import type { AxiosResponse, AxiosError } from 'axios';
import MutationEndpointConstants from "../constants/MutationEndpointConstants.js";
import * as baseMutation from "./baseMutation.js";

type RegisterParams = {
    firstName: string,
    lastName: string,
    dob: Date,
    phoneNumber: string,
    email: string,
    password: string,
};

export const performMutation = async (
    params: RegisterParams,
    // $FlowFixMe
    onSuccess: (response: AxiosResponse) => void,
    // $FlowFixMe
    onFailure: (error: AxiosError) => void): Promise<AxiosResponse<any, any>> => {

    await baseMutation.performMutation(
        MutationEndpointConstants.REGISTER,
        params,
        onSuccess,
        onFailure,
    );
}