// @flow

const QueryConstants = {
    ALL_USERS: 'all_users',
    USER_DATA: 'user_data',
    DEFAULT_DATAS_TALE_TIME_MS: 15 * 60 * 1000, // 15min
    USER_DATA_STALE_TIME_MS: 15 * 60 * 1000, // 15min
    ASSET_DATA_STALE_TIME_MS: 15 * 60 * 1000, // 15min
    BOOKING_DATA_STALE_TIME_MS: 15 * 60 * 1000, // 15min
    MODS_DATA: 'mods_data',
    AIRCRAFT_ASSET_DATA: 'aircraft_asset_data',
    INSTRUCTOR_ASSET_DATA: 'instructor_asset_data',
    ROOM_ASSET_DATA: 'room_asset_data',
    BOOKING_DATA_UNSPECIFIED: 'booking_data_unspecified',
    BOOKING_DATA_AIRCRAFT: 'booking_data_aircraft',
    BOOKING_DATA_INSTRUCTOR: 'booking_data_instructor',
    BOOKING_DATA_ROOM: 'booking_data_room',
    SINGLE_BOOKING_DATA: 'single_booking_data',

};

export default QueryConstants;