// @flow

export type ModNameType = "ASSET_MANAGEMENT" | "BOOKINGS" | "COMPLIANCE" | "MAINTENANCE" |
    "MOBILE_TRACKING" | "PAYMENTS" | "SOCIAL_AREA" | "STATS" | "STUDENT_RECORDS";

export const ModName = {
    ASSET_MANAGEMENT: "ASSET_MANAGEMENT",
    BOOKINGS: "BOOKINGS",
    COMPLIANCE: "COMPLIANCE",
    MAINTENANCE: "MAINTENANCE",
    MOBILE_TRACKING: "MOBILE_TRACKING",
    PAYMENTS: "PAYMENTS",
    SOCIAL_AREA: "SOCIAL_AREA",
    STATS: "STATS",
    STUDENT_RECORDS: "STUDENT_RECORDS",
}

export type ModType = {
    modId: number, // random identifier, currently below 1000
    modName: ModNameType,
    hasUI: boolean
}

export const MOD_CONFIG = [
    {
        modId: 637,
        modName: ModName.ASSET_MANAGEMENT,
        hasUI: true,
    },
    {
        modId: 805,
        modName: ModName.BOOKINGS,
        hasUI: true,
    },
    {
        modId: 372,
        modName: ModName.COMPLIANCE,
        hasUI: true,
    },
    {
        modId: 974,
        modName: ModName.MAINTENANCE,
        hasUI: true,
    },
    {
        modId: 462,
        modName: ModName.MOBILE_TRACKING,
        hasUI: false,
    },
    {
        modId: 65,
        modName: ModName.PAYMENTS,
        hasUI: true,
    },
    {
        modId: 379,
        modName: ModName.SOCIAL_AREA,
        hasUI: true,
    },
    {
        modId: 924,
        modName: ModName.STATS,
        hasUI: true,
    },
    {
        modId: 269,
        modName: ModName.STUDENT_RECORDS,
        hasUI: true,
    },
];