// @flow

import type { AssetTypeType } from "../types/AssetType.js";

import AssetUtils from "../utils/AssetUtils.js";
import CreateAssetModal from "./CreateAssetModal.react";

import React from "react";
import { useState } from "react";
import { Button, } from "react-bootstrap";
import { useQuery } from "react-query";

const CreateAssetButton = ({ assetType }: { assetType: AssetTypeType }): React.Node => {
    const [showCreationModal, setShowCreationModal] = useState(false);

    const [shouldRefetch, setShouldRefetch] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [queryConstant, _] = AssetUtils.getQueryForType(assetType);
    const assetQuery = useQuery(queryConstant);

    if (shouldRefetch) {
        setShouldRefetch(false);
        assetQuery.refetch();
    }

    const openAssetCreationModal = () => {
        setShowCreationModal(true);
    }

    const dismissAssetCreationModal = () => {
        setShowCreationModal(false);
    }

    const onAssetCreated = () => {
        setShouldRefetch(true)
        setShowCreationModal(false);
    }
    return <>
        <Button
            variant="primary"
            onClick={openAssetCreationModal}
        >
            Create {AssetUtils.assetTypeToName(assetType)} Asset
        </Button>
        <CreateAssetModal assetType={assetType} onAssetCreated={onAssetCreated} shouldShow={showCreationModal} onCreateModalDismiss={dismissAssetCreationModal} />
    </>;
};

// TODO explain why this is disabled
export const CreateDisabledAssetButton = ({ assetType }: { assetType: AssetTypeType }): React.Node => {
    return <Button
        variant="primary"
        disabled
    >
        Create {AssetUtils.assetTypeToName(assetType)} Asset
    </Button>

};

export default CreateAssetButton;