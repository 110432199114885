// @flow

import type { BookingRelevanceType } from "../types/BookingRelevance";
import type { BookingType } from "../types/CalendarBookingType";

import { BookingRelevance } from "../types/BookingRelevance";
import moment from "moment-timezone";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const TIMESLOT_FONT_SIZE_MONTH_VIEW = "0.7em";
const INLINE_STYLE = { display: 'flex', flexWrap: 'nowrap' };

const getSingleAssetBookingTitleRenderer = (
    bookingRelevance: BookingRelevanceType,
    userName: ?string,
    instructorName: ?string): string => {
    let title = "";
    switch (bookingRelevance) {
        case BookingRelevance.MY_ASSOCIATED_BOOKINGS:
            title = "My Booking";
            if (instructorName) {
                title += " w/ " + instructorName;
            }
            break;
        case BookingRelevance.MY_ASSOCIATED_INSTRUCTIONS:
            title = "Instructing ";
            if (userName) {
                title += userName;
            }
            break;
        case BookingRelevance.PRIVATE:
            title = "Reserved";
            break;
        case BookingRelevance.PUBLIC:
            if (userName) {
                title += userName;
            }
            if (instructorName) {
                title += "w/ " + instructorName;
            }
            break;
    }
    return title;
}

const getSingleAssetMonthEventFormatter = ({ event, isAllDay, ...props }: { event: BookingType, isAllDay: boolean }): React.Node => {
    const startTimeMoment = moment(event.start);
    const startTimeStr = startTimeMoment.format("HH:mm");
    const endTimeMoment = moment(event.end);
    const endTimeStr = endTimeMoment.format("HH:mm");

    const dayDiff = endTimeMoment.diff(startTimeMoment, 'days');
    return <Container className="p-0">
        {/* 
            We must make sure the event view is inline, especially for monthly view,
            otherwise it will push down the "more" button:
            https://github.com/jquense/react-big-calendar/issues/2058
         */}
        <Row className="g-0" style={INLINE_STYLE}>
            {/* separate title from time if there's no superscript */}
            <Col className="pe-1">
                <Row className="g-0" style={INLINE_STYLE}>
                    <Col md="auto">{`[${startTimeStr} - ${endTimeStr}]`}</Col>
                    {
                        dayDiff > 0
                            ? <Col md="auto"><sup>{`+${dayDiff}`}</sup></Col>
                            : null
                    }
                </Row>
            </Col>
            <Col>{event.title}</Col>
        </Row>

    </Container>
}

const FeatureConfigHelpers = {
    getSingleAssetBookingTitleRenderer,
    getSingleAssetMonthEventFormatter
};

export default FeatureConfigHelpers;