// @flow

const QueryEndpointConstants = {
    ASSETS: '/assets',
    BOOKINGS: '/bookings',
    USER: '/getUser',
    USERID_NAME_MAPPING: '/getUser/all',
    VIEW_BOOKING: '/bookings/view',
};

export default QueryEndpointConstants;