// @flow

import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

type Props = {
    imgSrc?: string,
    title: string,
    // if nothing specified, just navigate to /
    onImageClick?: () => void;
};

const ModHeaderComponent = (props: Props): React.Node => {
    const navigate = useNavigate();
    const onClick = (props.onImageClick) ?? (() => { });
    return <Container>
        <Row className="justify-content-md-center" style={{ display: 'inline-flex', width: "100%" }}>
            {
                props.imgSrc
                    ? <Col md={1} style={{ marginLeft: 0, paddingLeft: 0, display: 'flex', alignItems: 'flex-start' }}>
                        <Image thumbnail src={props.imgSrc} style={{ width: "50px", height: "50px", cursor: "pointer" }} onClick={onClick} />
                    </Col>
                    : null
            }
            <Col><h1>{props.title}</h1></Col>
        </Row>
    </Container>
};

export default ModHeaderComponent;