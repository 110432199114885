// @flow

// $FlowFixMe
import type { AxiosError } from "axios";
import { toast } from "react-toastify";

// $FlowFixMe
export const getToastMessage = (error: AxiosError): string => {
    return error?.response?.data?.message ?? error?.response?.statusText ?? 'An error ocurred';
}

export const getToastConfig = (errorType: number): Object => {
    return { toastId: errorType };
}
