// @flow
import 'bootstrap/dist/css/bootstrap.css';

import '../App.css';
import React from 'react';
import { useState } from "react";
import { Accordion, AccordionHeader, AccordionItem, Button, Col, Container, Row } from 'react-bootstrap';
import BookingCalendar from './BookingCalendar.react';
import ModHeaderComponent from "../mods/ModHeaderComponent.react";
import BookingAssetSelector from "./BookingAssetSelector.react";
import { AssetType } from "../types/AssetType";
import AssetSwitcherButton from "./AssetSwitcherButton.react";




const BookingRootComponent = (): React.Node => {
    const defaultSelectedAircraftId: ?string = null;
    const [selectedAircraftId, setSelectedAircraftId] = useState(defaultSelectedAircraftId);
    const defaultSelectedInstructorId: ?string = null;
    const [selectedInstructorId, setSelectedInstructorId] = useState(defaultSelectedInstructorId);
    const defaultSelectedRoomId: ?string = null;
    const [selectedRoomId, setSelectedRoomId] = useState(defaultSelectedRoomId);

    const [activeAssetSelectorType, setActiveAssetSelectorType] = useState(AssetType.AIRCRAFT);

    const assetSelector = activeAssetSelectorType === AssetType.AIRCRAFT ?
        <BookingAssetSelector
            assetType={AssetType.AIRCRAFT}
            selectedAssetId={selectedAircraftId}
            setSelectedAssetId={setSelectedAircraftId}
            style={{ display: 'flex', alignItems: 'flex-start' }} /> :
        activeAssetSelectorType === AssetType.ROOM ?
            <BookingAssetSelector
                assetType={AssetType.ROOM}
                selectedAssetId={selectedRoomId}
                setSelectedAssetId={setSelectedRoomId}
                style={{ display: 'flex', alignItems: 'flex-start' }} /> : null;

    return (
        <Container fluid="md">
            <Row className='mt-5'>
                <ModHeaderComponent title={"Bookings"} />
            </Row>
            <Row className='mt-4'>
                <Col md={12} lg={10} style={{ marginLeft: 0, paddingLeft: 0, display: 'flex', alignItems: 'flex-start' }}>
                    <AssetSwitcherButton
                        activeAssetSelectorType={activeAssetSelectorType}
                        setActiveAssetSelectorType={setActiveAssetSelectorType} />
                </Col>
            </Row>
            <Row className="mt-2 mb-4" style={{ display: 'flex', alignItems: 'flex-start' }} >
                <Col md="auto">
                    {assetSelector}
                </Col>
                <Col md={12} lg={10}>
                    <BookingAssetSelector
                        assetType={AssetType.INSTRUCTOR}
                        selectedAssetId={selectedInstructorId}
                        setSelectedAssetId={setSelectedInstructorId}
                        style={{ display: 'flex', alignItems: 'flex-start' }} />
                </Col>
            </Row>
            <Container fluid="md" className='mt-2' style={{ paddingLeft: '0', paddingRight: '0' }}>
                <BookingCalendar
                    assetType={activeAssetSelectorType}
                    selectedAssetId={selectedAircraftId}
                    selectedInstructorId={selectedInstructorId} />
            </Container>
        </Container >

    );
}

export default BookingRootComponent;