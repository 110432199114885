// @flow

import QueryConstants from "./QueryConstants.js";

const QueryDefaultParamsConstants = {
    DEFAULT_QUERY_PARAMS: {
        refetchOnMount: false,
        suspense: true,
        staleTime: QueryConstants.DEFAULT_DATAS_TALE_TIME_MS,
    },
};

export default QueryDefaultParamsConstants;