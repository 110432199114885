//@flow

import React from 'react';
import '../../App.css';
import logo from "../../logo.png";
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import LogOutButton from "../../common_components/LogOutButton.react";

type Props = {
    firstName: string,
    lastIp?: ?string,
    lastLoginAt?: ?string,
}

const LoggedInHeader = ({ firstName, lastIp, lastLoginAt }: Props): React.Node =>
    <Navbar
        expand="lg"
        fixed="top"
        className="bg-body-tertiary"
        style={{ background: "linear-gradient(#575e7d, #30375f)", backgroundSize: 'cover' }}
        data-bs-theme="dark"
    >
        <Container>
            <Navbar.Brand href="/">
                <Image src={logo} thumbnail style={{ width: '2em' }} />
            </Navbar.Brand>
            <Navbar.Brand href="/">The Modern Flying School</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto" navbarScroll>
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/bookings">Bookings</Nav.Link>
                    <NavDropdown title={"👤" + " " + firstName} id="basic-nav-dropdown">
                        <NavDropdown.Item href="#">View My Bookings</NavDropdown.Item>
                        <NavDropdown.Item href="#">Settings</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#">
                            <LogOutButton />
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>;

export default LoggedInHeader;