// @flow


import type { BookingPrivacyPolicyType } from "../types/BookingPrivacyPolicyType.js";
import type { ModNameType } from "../types/Mods.js";
import type { UserRoleType } from "../types/UserRole.js";

import { BookingPrivacyPolicy } from "../types/BookingPrivacyPolicyType.js";
import { ModName } from "../types/Mods.js";
import { UserRole } from "../types/UserRole.js";

const FeatureConfig = {
  getDefaultNewUserRole(): UserRoleType {
    return UserRole.PPL_STUDENT;
  },
  enableFormDatetimeRounding(): boolean {
    return false;
  },
  shouldRequireEmailConfirmationForRegistration(): boolean {
    return false;
  },

  shouldDisableRegistration(): boolean {
    return false;
  },

  isDevelopment(): boolean {
    return process.env.NODE_ENV === 'development';
  },

  // todo allow for a complex validation too
  shouldUseSimplePasswordValidation(): boolean {
    return true;
  },

  getBookingPrivacyPolicy(): BookingPrivacyPolicyType {
    return BookingPrivacyPolicy.ALLOW_READS;
  },

  getDefaultTimezone(): string {
    return 'Europe/London';
  },

  getEnabledModsForApp(): Array<{ name: ModNameType, isEnabled: boolean }> {
    return [
      { name: ModName.ASSET_MANAGEMENT, isEnabled: false },
      { name: ModName.BOOKINGS, isEnabled: true },
      { name: ModName.COMPLIANCE, isEnabled: false },
      { name: ModName.MAINTENANCE, isEnabled: false },
      { name: ModName.MOBILE_TRACKING, isEnabled: false },
      { name: ModName.PAYMENTS, isEnabled: false },
      { name: ModName.SOCIAL_AREA, isEnabled: false },
      { name: ModName.STATS, isEnabled: false },
      { name: ModName.STUDENT_RECORDS, isEnabled: false },
    ]
  },

  getModsForRoles(): Array<{ role: UserRoleType, mods: Array<ModNameType> }> {
    const socialMemberMods = [ModName.SOCIAL_AREA];
    const studentAndHirerMods = [ModName.BOOKINGS].concat(socialMemberMods);
    const instructorMods = [ModName.STUDENT_RECORDS, ModName.MOBILE_TRACKING].concat(studentAndHirerMods);
    const opsMods = [ModName.COMPLIANCE, ModName.MAINTENANCE, ModName.STATS].concat(instructorMods);
    const adminMods = [ModName.ASSET_MANAGEMENT].concat(opsMods);

    return [
      { role: UserRole.SOCIAL_MEMBER, mods: socialMemberMods },
      { role: UserRole.PPL_STUDENT, mods: studentAndHirerMods },
      { role: UserRole.CPL_STUDENT, mods: studentAndHirerMods },
      { role: UserRole.SELF_HIRER, mods: studentAndHirerMods },
      { role: UserRole.INSTRUCTOR, mods: instructorMods },
      { role: UserRole.OPS, mods: opsMods },
      { role: UserRole.ADMIN, mods: adminMods },
    ]
  },

  // this is used to test the client and server stay in sync
  serialize(): string {
    const obj = {
      isDevelopment: FeatureConfig.isDevelopment(),
      getEnabledModsForApp: FeatureConfig.getEnabledModsForApp(),
      getModsForRoles: FeatureConfig.getModsForRoles()
    }
    return JSON.stringify(obj);
  },
}

// TODO SERIALIZE / DESERIALIZE this

export default FeatureConfig;

