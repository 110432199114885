// @flow
import './forms.css';

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import type { AssetTypeType } from "../types/AssetType.js";
import * as assetCreateMutation from "../mutations/assetCreateMutation.js";


type Props = {
    assetType: AssetTypeType,
    onAssetCreated: () => void;
}

const CreateAssetForm = (
    {
        assetType,
        onAssetCreated
    }: Props): React$MixedElement => {
    const [assetName, setAssetName] = useState('');

    const handleSubmit = async (e: any): Promise<void> => {
        e.preventDefault();

        await assetCreateMutation.performMutation(
            assetName,
            assetType,
            onAssetCreated,
            (error) => console.log(error)
        );
    };
    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='assetNameLabel'
                                label="Asset Name"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="text"
                                    value={assetName}
                                    autoFocus
                                    onChange={(e) => setAssetName(e.target.value)}
                                    required
                                />
                            </Form.FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='assetTypeLabel'
                                label="Asset Type"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="text"
                                    value={assetType}
                                    required
                                    disabled
                                    readOnly
                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <Button variant="primary" type="submit" style={{ borderRadius: '20px', width: '100%', paddingTop: '8px', fontWeight: 'bold' }} onClick={handleSubmit} >
                            Create Asset
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container >
    );
};

export default CreateAssetForm;