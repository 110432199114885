// @flow
import './forms.css';
import img from '../img/circle_green_checkmark.png';

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FeatureConfig from "../feature_config/FeatureConfig";

const PostRegistrationMessage = (): React$MixedElement => {
    const messageTitle = FeatureConfig.shouldRequireEmailConfirmationForRegistration()
        ? 'Please check your email'
        : 'Account created';
    const messageBody = FeatureConfig.shouldRequireEmailConfirmationForRegistration()
        ? 'Thank you for registering. Please confirm your email by clicking the link you have received.'
        : 'Please proceed to login.';
    return (
        <Card style={{ width: '18em', backgroundColor: 'rgba(0,0,0,0)', border: 'none', width: '100%', alignItems: 'center' }}>
            <Card.Img variant="top" src={img} style={{ width: '100px' }} />
            <Card.Body>
                <Card.Title style={{ color: 'white' }}>{messageTitle}</Card.Title>
                <Card.Text style={{ color: 'white' }}>{messageBody}</Card.Text>
            </Card.Body>
        </Card>
    );


};

export default PostRegistrationMessage;