// @flow
import type { AssetTypeType } from "../types/AssetType.js";

import CreateAssetForm from "../forms/CreateAssetForm.react";
import AssetUtils from "../utils/AssetUtils.js";
import React from "react";
import { Modal } from "react-bootstrap";



type Props = {
    shouldShow: boolean,
    assetType: AssetTypeType,
    onAssetCreated: () => void;
    onCreateModalDismiss: () => void,
};

const CreateBookingModal = ({
    shouldShow,
    assetType,
    onAssetCreated,
    onCreateModalDismiss
}: Props): React.Node => {
    return <Modal
        show={shouldShow}
        onHide={onCreateModalDismiss}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton>
            <Modal.Title>Create a New {AssetUtils.assetTypeToName(assetType)} Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CreateAssetForm assetType={assetType} onAssetCreated={onAssetCreated} />
        </Modal.Body>
    </Modal >


};

export default CreateBookingModal;