// @flow

const MutationEndpointConstants = {
    ASSET: {
        CREATE: '/assets/create',
    },
    BOOKING: {
        CREATE: '/bookings/create',
        EDIT: '/bookings/edit',
        DELETE: '/bookings/delete'
    },
    LOGIN: '/login',
    PASSWORD_CHANGE: '/password_change',
    REGISTER: '/register',
};

export default MutationEndpointConstants;