// @flow


import FeatureConfigHelpers from "./FeatureConfigHelpers";

const FeatureConfig = {
    showMultiDayTimes: true,
    getCustomBookingTitleRenderer: FeatureConfigHelpers.getSingleAssetBookingTitleRenderer,
    getCustomEventFormatterForMonth: FeatureConfigHelpers.getSingleAssetMonthEventFormatter,
};

export default FeatureConfig;
