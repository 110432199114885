// @flow
import './forms.css';

import React, { Suspense, useState } from "react";
import axios from 'axios';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import moment from "moment-timezone";
import CalendarConstants from "../constants/CalendarConstants.js";
import { useQuery } from "react-query";
import QueryConstants from "../constants/QueryConstants.js";
import getSpecificBookingQuery from "../queries/getSpecificBookingQuery.js";
import type { BookingRelevanceType } from "../types/BookingRelevance.js";
import type { CensoredAnnotatedBookingData } from "../types/CensoredAnnotatedBookingData.js";
import BookingConstants from "../constants/BookingConstants.js";
import BookingAssetSelector from "../booking/BookingAssetSelector.react";
import { AssetType } from "../types/AssetType.js";
import type { AssetTypeType } from "../types/AssetType.js";
import FeatureConfig from "../feature_config/FeatureConfig.js";
import { BookingPrivacyPolicy } from "../types/BookingPrivacyPolicyType";
import { BookingRelevance } from "../types/BookingRelevance";
import * as bookingEditMutation from "../mutations/bookingEditMutation";
import * as bookingDeleteMutation from "../mutations/bookingDeleteMutation";
import { useUserRoleFromSessionCookie } from "../utils/CookieUtils";
import { UserRole } from "../types/UserRole";
import { useIsUserAllowedToMutateBookingsForOthers } from "../utils/PermissionUtils";
import UserSelector from "../users/UserSelector.react";


type Props = {
    bookingId: string,
    assetType: AssetTypeType,
    onBookingEdited: () => void,
    onBookingDeleted: () => void,
};

type InnerProps = {
    bookingId: string,
    assetType: AssetTypeType,
    onBookingEdited: () => void,
    onBookingDeleted: () => void,
    bookingStartDate: Date,
    bookingEndDate: Date,
    bookingInstructorId: ?string,
    bookingAssociatedUserId: ?string,
    bookedAssetId: ?string,
    bookingRelevance: BookingRelevanceType,
};

const buttonStyle = {
    borderRadius: '20px',
    width: '50%',
    marginTop: '8px',
    marginLeft: '4px',
    marginRight: '4px',
    fontWeight: 'bold'
};


const _ViewAndEditBookingForm = (
    {
        bookingId,
        assetType,
        onBookingEdited,
        onBookingDeleted,
        bookingStartDate,
        bookingEndDate,
        bookingInstructorId,
        bookingAssociatedUserId,
        bookedAssetId,
        bookingRelevance,
    }: InnerProps): React$MixedElement => {
    const [selectedAssetId, setSelectedAssetId] = useState(bookedAssetId);
    const [selectedInstructorId, setSelectedInstructorId] = useState(bookingInstructorId);
    const [startDate, setStartDate] = useState(bookingStartDate);
    const [endDate, setEndDate] = useState(bookingEndDate);

    const [error, setError] = useState('');
    const userRole = useUserRoleFromSessionCookie();

    const [selectedUserId, setSelectedUserId] = useState(bookingAssociatedUserId);

    const isOwnBooking = [BookingRelevance.MY_ASSOCIATED_BOOKINGS, BookingRelevance.MY_ASSOCIATED_INSTRUCTIONS].includes(bookingRelevance);
    const isUserAllowedToMutateArbitraryBookings = useIsUserAllowedToMutateBookingsForOthers();
    const shouldEnableEditing = isOwnBooking || isUserAllowedToMutateArbitraryBookings;


    const handleEditSubmit = async (e: any): Promise<void> => {
        if (!shouldEnableEditing) {
            return;
        }
        e.preventDefault();

        await bookingEditMutation.performMutation(
            bookingId,
            selectedAssetId,
            startDate,
            endDate,
            selectedInstructorId,
            selectedUserId,
            onBookingEdited,
            () => { }
        );
    };

    const handleDeleteSubmit = async (e: any): Promise<void> => {
        if (!shouldEnableEditing) {
            return;
        }
        e.preventDefault();

        await bookingDeleteMutation.performMutation(bookingId, onBookingDeleted, () => { });
    };
    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={10}>
                    <Form onSubmit={handleEditSubmit}>
                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='startDateLabel'
                                label="Start"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="datetime-local"
                                    disabled={!shouldEnableEditing}
                                    value={moment(startDate).tz(CalendarConstants.TIMEZONE).format('YYYY-MM-DD HH:mm')}
                                    onChange={(e) => {
                                        const startDateStr = moment(e.target.value).tz(CalendarConstants.TIMEZONE).utc().format('YYYY-MM-DD HH:mm z');
                                        setStartDate(startDateStr);
                                    }}
                                    required
                                />
                            </Form.FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='endDateLabel'
                                label="End"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="datetime-local"
                                    disabled={!shouldEnableEditing}
                                    value={moment(endDate).tz(CalendarConstants.TIMEZONE).format('YYYY-MM-DD HH:mm')}
                                    onChange={(e) => {
                                        const endDateStr = moment(e.target.value).tz(CalendarConstants.TIMEZONE).utc().format('YYYY-MM-DD HH:mm z');
                                        setEndDate(endDateStr);
                                    }}
                                    required
                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <BookingAssetSelector
                            assetType={AssetType.INSTRUCTOR}
                            disabled={!shouldEnableEditing}
                            selectedAssetId={selectedInstructorId}
                            setSelectedAssetId={setSelectedInstructorId}
                            variant="outline-secondary"
                            style={{ marginBottom: '16px' }}
                        />

                        {
                            assetType === AssetType.ROOM ?
                                <BookingAssetSelector
                                    assetType={AssetType.ROOM}
                                    disabled={!shouldEnableEditing}
                                    selectedAssetId={selectedAssetId}
                                    setSelectedAssetId={setSelectedAssetId}
                                    variant="outline-secondary"
                                    // this will cause the selector to show up disabled
                                    hideIfNotEnoughAssets={false}
                                    style={{ marginBottom: '16px' }}
                                />
                                : // this ensures we show the aircraft selector for instructor assets too
                                <BookingAssetSelector
                                    disabled={!shouldEnableEditing}
                                    assetType={AssetType.AIRCRAFT}
                                    selectedAssetId={selectedAssetId}
                                    setSelectedAssetId={setSelectedAssetId}
                                    variant="outline-secondary"
                                    // this will cause the selector to show up disabled
                                    hideIfNotEnoughAssets={false}
                                    style={{ marginBottom: '16px' }}
                                />
                        }

                        {isUserAllowedToMutateArbitraryBookings
                            ? <UserSelector
                                selectedUserId={selectedUserId}
                                setSelectedUserId={setSelectedUserId}
                                variant="outline-secondary" />
                            : FeatureConfig.getBookingPrivacyPolicy() !== BookingPrivacyPolicy.MAX_PRIVACY
                                ? <UserSelector
                                    selectedUserId={selectedUserId}
                                    setSelectedUserId={setSelectedUserId}
                                    disabled={true}
                                    variant="outline-secondary" /> : null
                        }

                        {shouldEnableEditing ?

                            (<div className="d-flex justify-content-end mt-3">
                                <Button variant="primary" type="submit" disabled={!shouldEnableEditing} style={buttonStyle} onClick={handleEditSubmit} >
                                    Save
                                </Button>

                                <Button variant="danger" type="submit" disabled={!shouldEnableEditing} style={buttonStyle} onClick={handleDeleteSubmit} >
                                    Delete
                                </Button>
                            </div>) : null
                        }

                        {error && <p className="text-danger mt-2">{error}</p>}
                    </Form>
                </Col>
            </Row>
        </Container >
    );
};

const _ViewAndEditBookingFormWithLoader = (props: Props): React.Node => {
    const bookingQuery = useQuery(
        QueryConstants.SINGLE_BOOKING_DATA + "." + props.bookingId,
        () => getSpecificBookingQuery(props.bookingId),
        {
            refetchOnMount: false,
            suspense: true,
            staleTime: QueryConstants.ASSET_DATA_STALE_TIME_MS,
        });

    if (bookingQuery.error) {
        return <h2>An error ocurred.</h2>;
    }

    if (bookingQuery.isLoading || !bookingQuery.data) {
        return <Spinner animation="border" />;
    }

    // $FlowFixMe
    const bookingData: CensoredAnnotatedBookingData = bookingQuery.data;

    // eslint-disable-next-line react/jsx-pascal-case
    return <_ViewAndEditBookingForm
        bookingId={bookingData.bookingId}
        assetType={props.assetType}
        bookedAssetId={[AssetType.AIRCRAFT, AssetType.ROOM].includes(props.assetType) ? bookingData.assetId : null}
        bookingStartDate={bookingData.startDate}
        bookingEndDate={bookingData.endDate}
        bookingInstructorId={bookingData.associatedInstructorId}
        bookingAssociatedUserId={bookingData.associatedUserId}
        bookingRelevance={bookingData.relevance}
        onBookingEdited={props.onBookingEdited}
        onBookingDeleted={props.onBookingDeleted}
    />;
}

const ViewAndEditBookingFormContainer = (props: Props): React.Node => {
    if (props.bookingId === BookingConstants.NO_BOOKING_ID) {
        return <div />;
    }
    return <Suspense fallback={<Spinner animation="border" />}>
        {/*eslint-disable-next-line react/jsx-pascal-case */}
        <_ViewAndEditBookingFormWithLoader {...props} />
    </Suspense>;
}


export default ViewAndEditBookingFormContainer;