// @flow

import type { BookingType } from "../types/CalendarBookingType.js";
import type { CensoredAnnotatedBookingData } from "../types/CensoredAnnotatedBookingData.js";

import type { AssetTypeType } from "../types/AssetType.js";
import { AssetType } from "../types/AssetType.js";
import QueryConstants from "../constants/QueryConstants.js";
import { BookingRelevance } from "../types/BookingRelevance.js";
import ColourUtils from "./ColourUtils.js";

import moment from "moment-timezone";
import ClientOnlyFeatureConfig from "../feature_config/ClientOnlyFeatureConfig";

const SMALLER_FONT_SIZE = "0.8em";
const NORMAL_FONT_SIZE = "0.95em";
const MAX_LENGTH_NORMAL_FONT = 18;

export const getEventCellProps = (event: BookingType, start: Date, end: Date, isSelected: boolean): Object => {
    const fontSize = event.title.length > MAX_LENGTH_NORMAL_FONT ? SMALLER_FONT_SIZE : NORMAL_FONT_SIZE;

    let backgroundColor = ColourUtils.NAVY;
    switch (event.relevance) {
        case BookingRelevance.MY_ASSOCIATED_BOOKINGS:
            backgroundColor = ColourUtils.LIME_GREEN;
            break;
        case BookingRelevance.MY_ASSOCIATED_INSTRUCTIONS:
            backgroundColor = ColourUtils.HOT_PINK;
            break;
        case BookingRelevance.PRIVATE:
            backgroundColor = ColourUtils.NAVY;
            break;
        case BookingRelevance.PUBLIC:
            backgroundColor = ColourUtils.ROYAL_BLUE;
            break;
        default:
            backgroundColor = ColourUtils.NAVY;
            break;
    }
    const now = (moment().unix())
    if (end.getTime() < now) {
        // this event is in the past, colour in shades of grey
        backgroundColor =
            [
                BookingRelevance.MY_ASSOCIATED_BOOKINGS,
                BookingRelevance.MY_ASSOCIATED_INSTRUCTIONS
            ].includes(event.relevance) ? ColourUtils.LIGHT_GRAY : ColourUtils.DIM_GRAY;

    }
    return {
        style: {
            backgroundColor: backgroundColor,
            fontSize: fontSize
        }
    };
}

export const hasOverlap = (event1: BookingType, event2: BookingType): boolean => {
    // case1: event1's start is in between event2's start/end dates
    if (event1.start >= event2.start && event1.start < event2.end) {
        return true;
    }

    // case2: event1's end is in between event2's start/end dates
    if (event1.end > event2.start && event1.end <= event2.end) {
        return true;
    }

    // case3: event2 is fully contained in event1
    // checking for event2 start will not be sufficient to determine if event2 is contained
    // because of a scenario like |event1|event2| where event2 starts exactly when event1 ends
    if (event2.start >= event1.start && event2.start <= event1.end && event2.end <= event1.end) {
        return true;
    }

    return false;
};

export const isAssetBookingOverlappingOthers = (booking: BookingType, bookings: Array<BookingType>): boolean => {
    const relevantBookings = bookings.filter(b => b.assetId === booking.assetId && b.id !== booking.id);
    const overlappingBooking = relevantBookings.find(b => hasOverlap(booking, b));

    return !!overlappingBooking;
};

export const formatBookingToCalendarType = (
    booking: CensoredAnnotatedBookingData,
    assetMap: { [string]: string },
    allUserMap: { [string]: string }): BookingType => {
    let title = booking.assetId.substring(0, 8);

    const bookedAssetName = assetMap[booking.assetId] ?? "";
    const userName = allUserMap[booking.associatedUserId ?? ""] ?? ""
    const instructorId = booking.associatedInstructorId;
    const instructorName = allUserMap[instructorId ?? ""];
    switch (booking.relevance) {
        case BookingRelevance.MY_ASSOCIATED_BOOKINGS:
            title = bookedAssetName + " — My Booking";
            if (booking.associatedInstructorId && instructorName) {
                title += " w/ " + instructorName;
            }
            break;
        case BookingRelevance.MY_ASSOCIATED_INSTRUCTIONS:
            title = bookedAssetName + " — Instructing ";
            if (booking.associatedUserId && userName) {
                title += userName;
            }
            break;
        case BookingRelevance.PRIVATE:
            title = bookedAssetName + " — Reserved";
            break;
        case BookingRelevance.PUBLIC:
            title = bookedAssetName;
            if (booking.associatedUserId && userName) {
                title += " (" + userName + ") ";
            }
            if (booking.associatedInstructorId && instructorName) {
                title += "w/ " + instructorName;
            }
            break;
        default:
            title = booking.assetId.substring(0, 8);
            break;
    }

    if (ClientOnlyFeatureConfig.getCustomBookingTitleRenderer) {
        const usrNm = booking.associatedUserId ? allUserMap[booking.associatedUserId] : null;
        const instrNm = booking.associatedInstructorId ? allUserMap[booking.associatedInstructorId] : null;
        title = ClientOnlyFeatureConfig.getCustomBookingTitleRenderer(
            booking.relevance,
            usrNm,
            instrNm);
    }
    return {
        start: moment(booking.startDate).toDate(),
        end: moment(booking.endDate).toDate(),
        id: booking.bookingId,
        assetId: booking.assetId,
        relevance: booking.relevance,
        title: title,
        associatedInstructorId: instructorId
    }
};

export const isEqualBookingArray = (bookings1: Array<BookingType>, bookings2: Array<BookingType>): boolean => {
    if (bookings1.length !== bookings2.length) {
        return false;
    }

    const differentBooking = bookings1.find((booking1, idx) => {
        return booking1.assetId !== bookings2[idx].assetId || booking1.id !== bookings2[idx].id ||
            booking1.start.getTime() !== bookings2[idx].start.getTime() || booking1.end.getTime() !== bookings2[idx].end.getTime() ||
            booking1.title !== bookings2[idx].title;
    });
    return !differentBooking;
};

export const getQueryConstantFromAsset = (
    assetType: AssetTypeType,
    assetId: ?string,
    instructorId: ?string,
    centralDate: ?Date,): string => {
    let baseQueryConstant = QueryConstants.BOOKING_DATA_UNSPECIFIED;
    switch (assetType) {
        case (AssetType.AIRCRAFT):
            baseQueryConstant = QueryConstants.BOOKING_DATA_AIRCRAFT
            break;
        case (AssetType.INSTRUCTOR):
            baseQueryConstant = QueryConstants.BOOKING_DATA_INSTRUCTOR;
            break;
        case (AssetType.ROOM):
            baseQueryConstant = QueryConstants.BOOKING_DATA_ROOM;
            break;
        default:
            baseQueryConstant = QueryConstants.BOOKING_DATA_UNSPECIFIED;
            break;
    }

    const assetQueryKey = "." + (assetId ?? 'null');
    const instructorQueryKey = "." + (instructorId ?? 'null');
    // on purpose only include the dot if non-null to allow for prefix scan invalidations
    const dayQueryKey = centralDate ? ("." + centralDate.toISOString().split('T')[0]) : ""; // remove the time, only look at the day

    return baseQueryConstant + assetQueryKey + instructorQueryKey + dayQueryKey;
};

export const CalendarMonthEventComponent = ({ event, isAllDay, ...props }: { event: BookingType, isAllDay: boolean }): React.Node => {
    return ClientOnlyFeatureConfig.getCustomEventFormatterForMonth ?
        ClientOnlyFeatureConfig.getCustomEventFormatterForMonth({ event, isAllDay, ...props })
        : event.title;
}

const CalendarUtils = {
    getEventCellProps,
    hasOverlap,
    isAssetBookingOverlappingOthers,
    formatBookingToCalendarType,
    isEqualBookingArray,
    getQueryConstantFromAsset
};

export default CalendarUtils;
