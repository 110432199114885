// @flow
import './forms.css';

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as registerMutation from "../mutations/registerMutation.js";
import * as ClientValidationUtils from "../utils/ClientValidationUtils";
import FeatureConfig from "../feature_config/FeatureConfig";

const RegistrationForm = ({ showTitle }: { showTitle: boolean }): React$MixedElement => {
    const now = new Date();
    const uninitialized = '';
    const [firstName, setFirstName] = useState(uninitialized);
    const [lastName, setLastName] = useState(uninitialized);
    const [dob, setDob] = useState(now);
    const [phoneNumber, setPhoneNumber] = useState(uninitialized);
    const [email, setEmail] = useState(uninitialized);
    const [password, setPassword] = useState(uninitialized);

    const [error, setError] = useState(uninitialized);
    const navigate = useNavigate();

    const onRegisterSuccess = () => {
        navigate("/doneRegistration");
    };

    if (FeatureConfig.shouldDisableRegistration()) {
        return <div />;
    }

    // any required fields not filled in? any errors?
    const shouldDisableSubmission =
        (dob === now) ||
        email === uninitialized ||
        password === uninitialized ||
        error !== uninitialized;

    const handleSubmit = async (e: any): Promise<void> => {
        if (shouldDisableSubmission) {
            return;
        }
        e.preventDefault();

        await registerMutation.performMutation({
            firstName,
            lastName,
            dob,
            phoneNumber,
            email,
            password,
        }, onRegisterSuccess, () => { });
    };
    return (
        <Container fluid="md" className="mt-5">
            <Row className="justify-content-center">
                <Col xs={12} md={10} lg={8} xl={6}>
                    <Form onSubmit={handleSubmit}>
                        {showTitle ? <h2 className="text-center mb-4">Create account</h2> : null}
                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='firstNameLabel'
                                label="First name"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    placeholder="First name"
                                    value={firstName}
                                    autoComplete='on'
                                    onChange={(e) => setFirstName(e.target.value)}

                                />
                            </Form.FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='lastNameLabel'
                                label="Last name"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    placeholder="Last name"
                                    value={lastName}
                                    autoComplete='on'
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='dobLabel'
                                label="Date of Birth "
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="date"
                                    value={dob}
                                    autoComplete='on'
                                    required
                                    onChange={(e) => setDob(e.target.value)}
                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='phoneNumberLabel'
                                label="Phone number"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    placeholder="Enter your phone number"
                                    value={phoneNumber}
                                    autoComplete='on'
                                    onChange={(e) => setPhoneNumber(e.target.value)}

                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='emailLabel'
                                label="Email address"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="email"
                                    isValid={ClientValidationUtils.isValidEmail(email)}
                                    placeholder="Enter email"
                                    value={email}
                                    required
                                    autoComplete='email'
                                    onChange={(e) => {
                                        const email = e.target.value;
                                        setEmail(email);
                                        setError(ClientValidationUtils.isValidEmail(email) ? "" : "Invalid Email Address");
                                    }}

                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='passwordLabel'
                                label="Password"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="password"
                                    placeholder="Enter password"
                                    value={password}
                                    isValid={ClientValidationUtils.isPasswordValid(password)[0]}
                                    autoComplete='new-password'
                                    required
                                    onChange={(e) => {
                                        const pwd = e.target.value;
                                        setPassword(pwd);
                                        const [isPwdValid, validationMsg] = ClientValidationUtils.isPasswordValid(pwd);
                                        setError(isPwdValid ? '' : validationMsg);
                                    }}
                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <Button
                            disabled={shouldDisableSubmission}
                            variant="primary"
                            type="submit"
                            style={{ borderRadius: '20px', width: '100%', paddingTop: '8px', fontWeight: 'bold' }} >
                            REGISTER
                        </Button>

                        {<p className="text-danger mt-2">{error}</p>}
                    </Form>
                </Col>
            </Row>
        </Container >
    );
};

export default RegistrationForm;