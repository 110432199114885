// @flow

import axios from 'axios';
import type { Element } from 'react';


import React from "react";
import { useCookies } from 'react-cookie';
import CookieConstants from "../constants/CookieConstants.js";
import { clearCookies } from "../utils/CookieUtils.js";
import { Button } from 'react-bootstrap';

const doLogout = async (): Promise<void> => {
    await axios.post('/api/logout', {});
};

const LogOutButton = (): ?React$MixedElement => {
    const [_, setSessionDataCookie] = useCookies([CookieConstants.SESSION_DATA]);

    return (<div>
        <Button variant="outline-secondary" size="sm" onClick={async () => {
            await doLogout();
            clearCookies(setSessionDataCookie);
            window.location.reload();

        }}>Log Out</Button>
    </div>
    );
}



export default LogOutButton;