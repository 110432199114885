// @flow
import 'bootstrap/dist/css/bootstrap.css';

import '../App.css';
import React from 'react';
import homeIconImg from '../img/home_icon.png';
import ModHeaderComponent from "../mods/ModHeaderComponent.react";
import AssetTable from "./AssetTable.react";
import CreateAssetButton, { CreateDisabledAssetButton } from './CreateAssetButton.react';
import { AssetType } from "../types/AssetType.js";
import { Container } from 'react-bootstrap';


const customStyles = {
    assetTableContainerStyle: {

    }
};


const AssetRootComponent = (): React.Node => {
    return (
        <div>
            <ModHeaderComponent imgSrc={homeIconImg} title={"Asset management"} />
            <Container style={customStyles.assetTableContainerStyle}>
                <AssetTable style={{ marginBottom: '20px' }} assetType={AssetType.AIRCRAFT} />
                <CreateAssetButton assetType={AssetType.AIRCRAFT} />
            </Container>
            <Container style={customStyles.assetTableContainerStyle}>
                <AssetTable style={{ marginBottom: '20px' }} assetType={AssetType.INSTRUCTOR} />
                <CreateDisabledAssetButton assetType={AssetType.INSTRUCTOR} />
            </Container >
            <Container style={customStyles.assetTableContainerStyle}>
                <AssetTable style={{ marginBottom: '20px' }} assetType={AssetType.ROOM} />
                <CreateAssetButton assetType={AssetType.ROOM} />
            </Container>
        </div >

    );
}

export default AssetRootComponent;