// @flow 


import '../forms/forms.css';
import img from '../img/circle_green_checkmark.png';

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


export type PlaceholderModProps = {
    cardWidth?: string,
}

const PlaceholderMod = (props: PlaceholderModProps): React.Node =>
(<Card style={{ width: '18em', width: props.cardWidth ?? '200px', alignItems: 'stretch', display: 'flex', height: '100%' }}>
    <Card.Img variant="top" src={img} style={{ alignItems: "center", margin: "0 auto" }} />
    <Card.Body>
        <Card.Title>WIP</Card.Title>
        <Card.Text >
            This is just a placeholder mod. Good stuff is coming, keep checking.
        </Card.Text>
    </Card.Body>
</Card>);

export default PlaceholderMod;