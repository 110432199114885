// @flow

import { Navigate, Outlet } from "react-router-dom";
import CookieConstants from "../constants/CookieConstants";
import { extractDataFromCookie } from "../utils/CookieUtils";

import { useCookies } from "react-cookie";
import React from "react";

const LoggedInRoute = (): React.Node => {
    const [sessionDataCookie, _] = useCookies([CookieConstants.SESSION_DATA]);
    const parsedCookieData = extractDataFromCookie(sessionDataCookie?.session_data);

    if (!parsedCookieData ) {
        return <Navigate to="/login" />;;
    }

    if (parsedCookieData.passwordChangeSoftRequested) {
        return <Navigate to="/password_change" />
    }

    return  <Outlet />;
};

export default LoggedInRoute;