// @flow

import React from 'react';
import { Suspense, useEffect } from "react";
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { useQuery } from "react-query";
import QueryConstants from "../constants/QueryConstants.js";
import type { ReactSetStateFunction } from "react";
import { getAllUserIdToNameMappingQuery } from "../queries/getAllUserIdToNameMappingQuery";
import QueryDefaultParamsConstants from "../constants/QueryDefaultParamsConstants";

type Props = {
    disabled?: boolean,
    selectedUserId: ?string,
    setSelectedUserId: ReactSetStateFunction<?string>,
    variant?: string,
    style?: Object,
};


// eslint-disable-next-line react/jsx-pascal-case
const _UserSelector = ({ disabled, style, setSelectedUserId, selectedUserId, variant }: Props): React.Node => {
    const allUserQuery = useQuery(
        QueryConstants.ALL_USERS,
        getAllUserIdToNameMappingQuery,
        QueryDefaultParamsConstants.DEFAULT_QUERY_PARAMS
    );

    // $FlowFixMe
    const allUserMap: { [string]: strirng } = (allUserQuery.isError || allUserQuery.isLoading || !allUserQuery?.data?.data) ? {} : allUserQuery.data.data;

    if (allUserQuery.error) {
        return <Spinner variant="danger" />;
    }

    if (allUserQuery.isLoading) {
        return <Spinner variant="primary" />;
    }

    const renderSelectedUserName = (selectedUserId: string): string => {
        // todo: replace this with a user obj
        const selectedUserName = allUserMap[selectedUserId];
        if (!selectedUserName) {
            console.error("could not find a user with the provied id");
            return 'Name N/A';
        }
        return selectedUserName;
    }

    const onSelect = (eventKey: string): void => {
        const newSelectedUserId = eventKey === selectedUserId ? null : eventKey;
        setSelectedUserId(newSelectedUserId);
    }

    return (
        <DropdownButton
            id={"user_dropdown_selector"}
            className="mb-1"
            disabled={disabled}
            title={selectedUserId ? renderSelectedUserName(selectedUserId) : "Select a user"}
            size="lg"
            variant={variant ?? "secondary"}
            onSelect={onSelect}
            style={style} >
            {
                Object.keys(allUserMap).map((userId) =>
                    // we need a unique key prop because this is a list of items, but we won't really use "key"
                    <Dropdown.Item active={userId === selectedUserId} key={userId} eventKey={userId}>{allUserMap[userId]}</Dropdown.Item>
                )
            }

        </DropdownButton >
    );
};

const UserSelector = (props: Props): React.Node => {
    return <Suspense fallback={<Spinner animation="border" />}>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_UserSelector {...props} />
    </Suspense>;
}

export default UserSelector;