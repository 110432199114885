// @flow

const ErrorType = {
    ALL_BOOKINGS_QUERY: 1,
    ASSETS_FOR_TYPE_QUERY: 2,
    ALL_USERIDS_TO_NAME_MAPPING_QUERY: 3,
    SPECIFIC_BOOKING_QUERY: 4,
    USER_QUERY: 5,
    GENERIC_MUTATION: 6,
    GENERIC_MUTATION_SUCCESS: 7, // this is technically not an error type
}

export default ErrorType;