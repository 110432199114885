// @flow
import './forms.css';

// $FlowFixMe
import type { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import CookieConstants, { getBrandNewCookieExpiryDate } from "../constants/CookieConstants.js";
import * as loginMutation from "../mutations/loginMutation.js";

const LoginForm = ({ showTitle }: { showTitle: boolean }): React$MixedElement => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLongerSession, setIsLongerSession] = useState(false);
    const [error, setError] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [_, setSessionDataCookie] = useCookies([CookieConstants.SESSION_DATA]);

    // $FlowFixMe
    const onLoginSuccess = (response: AxiosResponse): void => {
        const token = response.data?.token;
        const firstName = response.data?.firstName;
        const role = response.data?.role;
        const lastIp = response.data?.lastIp;
        const lastLoginAt = response.data?.lastLoginAt;
        const passwordChangeSoftRequested = response.data?.passwordChangeSoftRequested;

        if (token) {
            const sessionData = { token, firstName, role, lastIp, lastLoginAt, passwordChangeSoftRequested };
            const cookieExpiryDate = getBrandNewCookieExpiryDate(isLongerSession);
            setSessionDataCookie(CookieConstants.SESSION_DATA, sessionData, { path: '/', expires: cookieExpiryDate });
        }
    }

    const handleSubmit = async (e: any): Promise<void> => {
        e.preventDefault();

        await loginMutation.performMutation(email, password, isLongerSession, onLoginSuccess, () => { });
    };
    return (
        <Container fluid="md" className="mt-5">
            <Row className="justify-content-center">
                <Col xs={12} md={10} lg={8} xl={6}>
                    <Form onSubmit={handleSubmit}>
                        {showTitle ? <h2 className="text-center mb-4">Login</h2> : null}
                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='emailLabel'
                                label="Email address"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    autoComplete='email'
                                    required
                                    onChange={(e) => setEmail(e.target.value)}

                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='passwordLabel'
                                label="Password"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="password"
                                    placeholder="Enter password"
                                    value={password}
                                    autoComplete='current-password'
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                id="longSessionSwitch"
                                className="larger-checkbox"
                                label="Stay signed in on this device?"
                                style={{ textAlign: "left", color: "rgba(255, 255, 255, .7)" }}
                                checked={isLongerSession}
                                onChange={() => setIsLongerSession(!isLongerSession)}
                            />

                        </Form.Group>

                        <Button variant="primary" type="submit" style={{ marginTop: '15px', borderRadius: '20px', width: '100%', paddingTop: '8px', fontWeight: 'bold' }}>
                            LOGIN
                        </Button>

                        {error && <p className="text-danger mt-2">{error}</p>}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default LoginForm;