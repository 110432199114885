// @flow

const Colours = {
    AQUA: "#00FFFF",
    AQUAMARINE: "#7FFFD4",
    BLANCHED_ALMOND: "#FFEBCD",
    BLACK: "#000000",
    BLUE: "#0000FF",
    BLUE_VIOLET: "#8A2BE2",
    BROWN: "#A52A2A",
    BURLY_WOOD: "#D2691E",
    CADET_BLUE: "#5F9EA0",
    CORAL: "#FF7F50",
    CORNFLOWER_BLUE: "#6495ED",
    CRIMSON: "#DC143C",
    CYAN: "#00FFFF",
    DIM_GRAY: "#696969",
    DODGER_BLUE: "#1E90FF",
    HOT_PINK: "#FF69B4",
    LIGHT_BLUE: "#ADD8E6",
    LIGHT_GRAY: "#D3D3D3",
    LIME_GREEN: "#32CD32",
    NAVY: "#000080",
    ROYAL_BLUE: "#4169E1",
    YELLOW_GREEN: "#9ACD32",

};

export default Colours;