// @flow
import type { AssetData } from "../types/AssetData.js";

import { toast } from "react-toastify";
import axios from "axios";
import { getToastMessage, getToastConfig } from "../errors/errorUtils.js";
import ErrorType from "../errors/ErrorType.js";
import QueryEndpointConstants from "../constants/QueryEndpointConstants.js";
export const getAssetsForTypeQuery = async (assetType: string): Promise<Array<AssetData>> => {
    const assetsReponse = await
        axios.get("/api" + QueryEndpointConstants.ASSETS, {
            params: {
                assetType
            }
        }).catch((error) => {
            toast.error(getToastMessage(error), getToastConfig(ErrorType.ASSETS_FOR_TYPE_QUERY));
        });
    return assetsReponse?.data ?? [];
};