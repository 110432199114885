// @flow

// $FlowFixMe unsafe type import from untyped module
import type { AxiosResponse, AxiosError } from 'axios';

import { toast } from "react-toastify";
import axios from "axios";
import { getToastMessage, getToastConfig } from "../errors/errorUtils.js";
import ErrorType from "../errors/ErrorType.js";


// $FlowFixMe
export const performMutation = async (endpoint: string, data: Object, onSuccess: (AxiosResponse<any, any>) => void, onFailure: (AxiosError<mixed, any>) => void, errorType ?: number): Promise < AxiosResponse < any, any >> => {

    const response = await axios.post("/api" + endpoint, data).then(resp => {
        onSuccess(resp);
        return resp;
    }).catch(error => {
        toast.error(getToastMessage(error), getToastConfig(errorType ?? ErrorType.GENERIC_MUTATION));
    });

    return response;
} 