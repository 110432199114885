// @flow

import React from 'react';
import { Button } from 'react-bootstrap';
import { AssetType } from "../types/AssetType";
import type { AssetTypeType } from "../types/AssetType";
import type { ReactSetStateFunction } from "react";
import QueryConstants from "../constants/QueryConstants";
import { getAssetsForTypeQuery } from "../queries/getAssetsForTypeQuery";
import AssetUtils from "../utils/AssetUtils";
import { useQuery } from "react-query";

type Props = {
    activeAssetSelectorType: AssetTypeType,
    setActiveAssetSelectorType: ReactSetStateFunction<AssetTypeType>
}

// needs to be uppercase due to react constraints
const AreRoomAssetsAvailable = (): boolean => {
    const assetQuery = useQuery(
        AssetUtils.getQueryConstant(AssetType.ROOM),
        () => getAssetsForTypeQuery(AssetType.ROOM),
        {
            refetchOnMount: false,
            staleTime: QueryConstants.ASSET_DATA_STALE_TIME_MS,
        });

    if (assetQuery.isError) {
        return false;
    }

    if (assetQuery.isLoading) {
        return false;
    }

    if (!assetQuery.data) {
        return false;
    }

    if (assetQuery.data.length === 0) {
        return false;
    }

    return true;
}


const AssetSwitcherButton = ({ activeAssetSelectorType, setActiveAssetSelectorType }: Props): ?React.Node => {
    if (!AreRoomAssetsAvailable()) {
        return null;
    }

    const switchAssetTypeButtonLabel = activeAssetSelectorType === AssetType.AIRCRAFT ?
        "Book a room instead?" : "Book an aircraft instead?";
    const switchAssetTypeButtonAction = activeAssetSelectorType === AssetType.AIRCRAFT
        ? () => setActiveAssetSelectorType(AssetType.ROOM)
        : () => setActiveAssetSelectorType(AssetType.AIRCRAFT);

    return <Button variant="link" onClick={switchAssetTypeButtonAction}>{switchAssetTypeButtonLabel}</Button>;
}

export default AssetSwitcherButton;