// @flow

// $FlowFixMe unsafe type import from untyped module
import type { AxiosResponse, AxiosError } from 'axios';
import MutationEndpointConstants from "../constants/MutationEndpointConstants.js";
import * as baseMutation from "./baseMutation.js";
import type { AssetTypeType } from "../types/AssetType.js";
import { toast } from 'react-toastify';
import ErrorType from "../errors/ErrorType";


export const performMutation = async (
    bookingId: string,
    // $FlowFixMe
    onSuccess: (response: AxiosResponse) => void,
    // $FlowFixMe
    onFailure: (error: AxiosError) => void): Promise<AxiosResponse<any, any>> => {
    // $FlowFixMe
    const toastOnSuccess = (response: AxiosResponse): void => {
        toast.success(
            "Booking deleted successfully.",
            { toastId: ErrorType.GENERIC_MUTATION_SUCCESS }
        );
        onSuccess(response);
    }

    await baseMutation.performMutation(
        MutationEndpointConstants.BOOKING.DELETE,
        { bookingId },
        toastOnSuccess,
        onFailure,
    );
}