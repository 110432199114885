// @flow
import React from "react";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import { ErrorBoundary } from "react-error-boundary";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import CreateBookingModal from "./CreateBookingModal.react";
import FeatureConfig from "../feature_config/FeatureConfig.js";
import { useQuery } from "react-query";
import QueryConstants from "../constants/QueryConstants.js";
import getBookingsQuery from "../queries/getBookingsQuery.js";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import type { AssetTypeType } from "../types/AssetType.js";
import CalendarConstants from "../constants/CalendarConstants.js";
import type { BookingType } from "../types/CalendarBookingType.js";
import CalendarUtils from "../utils/CalendarUtils.js";
import { AssetType } from "../types/AssetType.js";
import { getQueryConstantFromAsset } from "../utils/CalendarUtils.js";
import ViewAndEditBookingModal from "./ViewAndEditBookingModal.react";
import BookingConstants from "../constants/BookingConstants.js";
import AssetUtils from "../utils/AssetUtils";
import { getAssetsForTypeQuery } from "../queries/getAssetsForTypeQuery.js";
import { BookingRelevance } from "../types/BookingRelevance.js";
import QueryDefaultParamsConstants from "../constants/QueryDefaultParamsConstants.js";
import { getAllUserIdToNameMappingQuery } from "../queries/getAllUserIdToNameMappingQuery.js";
import * as bookingEditCalendarMutation from "../mutations/bookingEditMutation";
import type { BookingRelevanceType } from "../types/BookingRelevance";
import ErrorFallback from "../common_components/ErrorFallback.react";
import { CalendarMonthEventComponent } from "../utils/CalendarUtils";
import ClientOnlyFeatureConfig from "../feature_config/ClientOnlyFeatureConfig";

type Props = {
    selectedAssetId: ?string,
    selectedInstructorId: ?string,
    assetType: AssetTypeType
}

moment.updateLocale('en', {
    week: {
        dow: 1,
        doy: 1,
    },
})
const LOCALIZER = momentLocalizer(moment);

const components = {
    month: {
        event: CalendarMonthEventComponent
    },
};


const _BookingCalendar = (
    props: Props
): React$MixedElement => {
    const DragAndDropCalendar = withDragAndDrop(Calendar);
    const defaultBookings: Array<BookingType> = [];
    const [bookings, setBookings] = useState(defaultBookings);
    const isNewDataAvailableRef = useRef(true);

    const availableViews = props.selectedAssetId != null ? [Views.WEEK, Views.MONTH] : [Views.DAY, Views.AGENDA];
    const defaultView = props.selectedAssetId != null ? Views.WEEK : Views.DAY;
    const [calView, setCalView] = useState(defaultView); // calendar view

    const createBookingStartDateRef = useRef(moment().toDate());
    const createBookingEndDateRef = useRef(moment().add(1, "hour").toDate());
    const [showCreateBookingModal, setShowCreatingBookingModal] = useState(false);
    const [showViewAndEditBookingModal, setShowViewAndEditBookingModal] = useState(false);
    const selectedBookingIdRef = useRef(BookingConstants.NO_BOOKING_ID);

    const [centralDate, setCentralDate] = useState(moment());;

    const bookingsQuery = useQuery(
        getQueryConstantFromAsset(props.assetType, props.selectedAssetId, props.selectedInstructorId, null),
        () => getBookingsQuery(centralDate, props.assetType, props.selectedAssetId, props.selectedInstructorId),
        {
            ...QueryDefaultParamsConstants.DEFAULT_QUERY_PARAMS,
            onSuccess: () => { isNewDataAvailableRef.current = true; }
        }
    );

    const assetQuery = useQuery(
        AssetUtils.getQueryConstant(props.assetType),
        () => getAssetsForTypeQuery(props.assetType),
        QueryDefaultParamsConstants.DEFAULT_QUERY_PARAMS
    );

    const allUserQuery = useQuery(
        QueryConstants.ALL_USERS,
        getAllUserIdToNameMappingQuery,
        QueryDefaultParamsConstants.DEFAULT_QUERY_PARAMS
    );

    useEffect(() => {
        if (!isNewDataAvailableRef.current) {
            return;
        }
        isNewDataAvailableRef.current = false;
        // $FlowFixMe
        const rawBookings = bookingsQuery.data?.bookings;
        if (!rawBookings) {
            return;
        }

        // $FlowFixMe
        const assetMap = (assetQuery.isError || assetQuery.isLoading || !assetQuery.data) ? {} : AssetUtils.getAssetMap(assetQuery.data);
        // $FlowFixMe
        const allUserMap: { [string]: strirng } = (allUserQuery.isError || allUserQuery.isLoading || !allUserQuery?.data?.data) ? {} : allUserQuery.data.data;

        const retrievedBookings = rawBookings.map(booking => CalendarUtils.formatBookingToCalendarType(booking, assetMap, allUserMap));
        if (CalendarUtils.isEqualBookingArray(retrievedBookings, bookings)) {
            return;
        }
        setBookings(retrievedBookings);

    }, [bookingsQuery, setBookings, bookings, isNewDataAvailableRef, assetQuery],);

    if (bookingsQuery.error) {
        return <Spinner variant="danger" />;
    }
    if (bookingsQuery.isLoading) {
        return <Spinner variant="primary" />;
    }

    const moveBooking =
        async ({ event, start, end }: { event: BookingType, start: Date, end: Date }) => {
            const existingBooking = bookings.find((booking) => event.id === booking.id);
            if (!existingBooking) {
                console.error("The modified booking does not exist");
                return;
            }
            const newBooking = {
                ...existingBooking,
                start,
                end
            };

            // update the optimistic view
            const nextBookings = bookings.map(existingBooking => {
                return existingBooking.id === event.id
                    ? newBooking
                    : existingBooking
            });

            if (CalendarConstants.FORBID_OVERLAPPING_BOOKINGS &&
                CalendarUtils.isAssetBookingOverlappingOthers(newBooking, bookings)) {
                return; // don't allow overlapping events
            }
            setBookings(nextBookings);

            await bookingEditCalendarMutation.performMutation(
                newBooking.id,
                newBooking.assetId,
                newBooking.start,
                newBooking.end,
                null, // no new instructor
                null, // no new targetUser
                () => { },
                () => { }
            );

            bookingsQuery.refetch(); // refetch regardless of success/failure
        };

    const resizeBooking =
        ({ event, start, end }: { event: BookingType, start: Date, end: Date }) => {
            // for all intents and purposes these are the same
            moveBooking({ event, start, end });
        };

    const onCreateModalDismiss = () => setShowCreatingBookingModal(false);
    const onViewAndEditModalDismiss = () => setShowViewAndEditBookingModal(false);

    const onBookingSelected = (booking: BookingType): void => {
        selectedBookingIdRef.current = booking.id;
        setShowViewAndEditBookingModal(true);
    }

    const onSlotSelected = (selectedSlot: { start: Date, end: Date }) => {
        if (props.selectedAssetId) {
            const tentativeBooking = {
                id: "", // to avoid NPEs, id must be specified
                title: "", // same
                assetId: props.selectedAssetId,
                start: selectedSlot.start,
                end: selectedSlot.end,
                relevance: BookingRelevance.MY_ASSOCIATED_BOOKINGS,
            };
            if (CalendarUtils.isAssetBookingOverlappingOthers(tentativeBooking, bookings)) {
                return;
            }
        }
        createBookingStartDateRef.current = selectedSlot.start;
        createBookingEndDateRef.current = selectedSlot.end;
        setShowCreatingBookingModal(true);
    };

    const onBookingCreated = () => {
        bookingsQuery.refetch();
        setShowCreatingBookingModal(false);
    }
    const onBookingEdited = () => {
        bookingsQuery.refetch();
        // $FlowFixMe
        setShowViewAndEditBookingModal(false);
    }

    const onBookingDeleted = onBookingEdited;

    /*
        (IMPORTANT!!) this happens when the user selects an asset
        The initial state of calView depends on the asset, but further state updates don't
        If the available views change (as a result of asset selection),
        this will cause calView to not be present in the availableViews
        This causes a weird crash in react-big-calendar:
        https://github.com/jquense/react-big-calendar/issues/2454
     
        So, if we find calView is no longer in the list of availableViews,
        we ensure we make it part of availableViews before we render RBC
    */
    if (!availableViews.includes(calView)) {
        setCalView(availableViews[0]);
        return <Spinner />;
    }

    const onNavigate = (d: Date) => {
        setCentralDate(d);
        // very inefficient. TODO: do a paginated query and only refetch if we don't have the data
        bookingsQuery.refetch();
    };


    // MONTH view has a higher aspect ratio, it does not look good in 1200 height
    const minHeight = calView === Views.MONTH ? 800 : 1200;


    return <div style={{ height: Math.min(1.75 * window.innerHeight, minHeight) }}>
        <DragAndDropCalendar
            selectable
            popup
            showMultiDayTimes={ClientOnlyFeatureConfig.showMultiDayTimes}
            components={components}
            eventPropGetter={CalendarUtils.getEventCellProps}
            onSelectEvent={onBookingSelected}
            onEventDrop={moveBooking}
            onSelectSlot={onSlotSelected}
            onNavigate={onNavigate}
            resizable={true}
            onEventResize={resizeBooking}
            localizer={LOCALIZER}
            draggableAccessor={(event) => event.relevance !== BookingRelevance.PRIVATE}
            startAccessor="start"
            endAccessor="end"
            // tile disabled -> TODO pass a function to disable this server side if needed
            events={bookings}
            views={availableViews} // if all assets need to be displayed, do it per day
            view={calView}
            onView={setCalView}
            defaultDate={centralDate} // TODO use date instead of default date?
            // the year is just a placeholder, only the last param (the hour param) matters
            min={moment(CalendarConstants.START_TIME_HOUR).toDate()}
            max={moment(CalendarConstants.END_TIME_HOUR).toDate()}
        />
        <CreateBookingModal
            shouldShow={showCreateBookingModal}
            assetType={props.assetType}
            initialStartDate={createBookingStartDateRef.current}
            initialEndDate={createBookingEndDateRef.current}
            initialInstructorId={props.assetType === AssetType.INSTRUCTOR ? props.selectedAssetId : null}
            bookedAssetId={[AssetType.AIRCRAFT, AssetType.ROOM].includes(props.assetType) ? props.selectedAssetId : null}
            onBookingCreated={onBookingCreated}
            onCreateModalDismiss={onCreateModalDismiss} />
        <ViewAndEditBookingModal
            shouldShow={showViewAndEditBookingModal}
            assetType={props.assetType}
            bookingId={selectedBookingIdRef.current}
            onBookingEdited={onBookingEdited}
            onBookingDeleted={onBookingDeleted}
            onModalDismiss={onViewAndEditModalDismiss}
        />
    </div>
};

// eslint-disable-next-line react/jsx-pascal-case
const BookingCalendar = (props: Props): React$MixedElement => {
    return <ErrorBoundary fallback={<ErrorFallback />}>
        <Suspense fallback={<Spinner animation="border" />}>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <_BookingCalendar {...props} />
        </Suspense>
    </ErrorBoundary>;
}

export default BookingCalendar;
