// @flow
import './forms.css';

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import type { AssetTypeType } from "../types/AssetType.js";
import * as assetCreateMutation from "../mutations/assetCreateMutation.js";
import * as ClientValidationUtils from "../utils/ClientValidationUtils.js";
import { clearCookies } from "../utils/CookieUtils.js";
import * as passwordChangeMutation from "../mutations/passwordChangeMutation.js";
import { useCookies } from 'react-cookie';
import CookieConstants from "../constants/CookieConstants.js";


type Props = {
    onPasswordChanged?: () => void;
}

const PasswordChangeForm = (
    {
        onPasswordChanged
    }: Props): React$MixedElement => {
    const [_, setSessionDataCookie] = useCookies([CookieConstants.SESSION_DATA]);
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const onSuccessfulChange = () => {
        if (onPasswordChanged) {
            onPasswordChanged();
        }
        clearCookies(setSessionDataCookie);
        navigate('/');
    };

    const shouldDisableSubmission = password === "" || error !== "";

    const handleSubmit = async (e: any): Promise<void> => {
        e.preventDefault();

        await passwordChangeMutation.performMutation(
            password,
            onSuccessfulChange,
            (error) => console.log(error)
        );
    };
    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Form onSubmit={handleSubmit}>
                        <h2 className="text-center mb-4">Change Password</h2>

                        {/* chrome warns if this dummy field is not provided */}
                        <input hidden type="text" readOnly autoComplete="username" value="{{...}}" />

                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='passwordLabel'
                                label="Password"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="password"
                                    autoFocus
                                    placeholder="Enter password"
                                    value={password}
                                    isValid={ClientValidationUtils.isPasswordValid(password)[0]}
                                    autoComplete='new-password'
                                    required
                                    style={{
                                        border: "1px solid #cccccc",
                                    }}
                                    onChange={(e) => {
                                        const pwd = e.target.value;
                                        setPassword(pwd);
                                        const [isPwdValid, validationMsg] = ClientValidationUtils.isPasswordValid(pwd);
                                        setError(isPwdValid ? '' : validationMsg);
                                    }}
                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <Button
                            disabled={shouldDisableSubmission}
                            className="mt-4"
                            variant="primary"
                            type="submit"
                            style={{ borderRadius: '20px', width: '100%', paddingTop: '8px', fontWeight: 'bold' }} >
                            Save New Password
                        </Button>

                        {<p className="text-danger mt-2">{error}</p>}
                    </Form>
                </Col>
            </Row>
        </Container >
    );



};

export default PasswordChangeForm;