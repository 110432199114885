// @flow

import FeatureConfig from "../feature_config/FeatureConfig";
import { BookingPrivacyPolicy } from "../types/BookingPrivacyPolicyType";
import { UserRole } from "../types/UserRole";
import { useUserRoleFromSessionCookie } from "./CookieUtils.js";

export const useIsUserAllowedToMutateBookingsForOthers = (): boolean => {
    const userRole = useUserRoleFromSessionCookie();

    return [UserRole.ADMIN, UserRole.OPS].includes(userRole) ||
        FeatureConfig.getBookingPrivacyPolicy() === BookingPrivacyPolicy.NO_PRIVACY;
}