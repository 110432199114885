// @flow 


import '../forms/forms.css';
import placeholderImg from '../img/circle_green_checkmark.png';

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


export type ModIntroCardProps = {
    title: string,
    content: string,
    link: string,
    cardWidth?: string,
    img?: string,
};

const ModIntroCard = (props: ModIntroCardProps): React.Node => {
    const navigate = useNavigate();
    return <Card style={{ width: '18em', width: props.cardWidth ?? '200px', alignItems: 'center', cursor: "pointer" }} onClick={() => navigate(props.link)} >
        <Card.Img variant="top" src={props.img ?? placeholderImg} />
        <Card.Body>
            <Card.Title>{props.title}</Card.Title>
            <Card.Text>
                {props.content}
            </Card.Text>
        </Card.Body>
    </Card >;
}

export default ModIntroCard;