// @flow

// $FlowFixMe unsafe type import from untyped module
import type { AxiosResponse, AxiosError } from 'axios';
import MutationEndpointConstants from "../constants/MutationEndpointConstants.js";
import * as baseMutation from "./baseMutation.js";
import type { AssetTypeType } from "../types/AssetType.js";
import { toast } from 'react-toastify';
import ErrorType from "../errors/ErrorType.js";


export const performMutation = async (
    bookingId: string,
    assetId: ?string,
    startDate: Date,
    endDate: Date,
    instructorId: ?string,
    targetUserId: ?string,
    // $FlowFixMe
    onSuccess: (response: AxiosResponse) => void,
    // $FlowFixMe
    onFailure: (error: AxiosError) => void): Promise<AxiosResponse<any, any>> => {

    // $FlowFixMe
    const toastOnSuccess = (response: AxiosResponse): void => {
        toast.success(
            "Booking updated successfully.",
            { toastId: ErrorType.GENERIC_MUTATION_SUCCESS }
        );
        onSuccess(response);
    }

    let optionalMutationFields = {};
    if (instructorId) {
        optionalMutationFields = {
            instructorId,
            ...optionalMutationFields,
        };
    }

    if (targetUserId) {
        optionalMutationFields = {
            targetUserId,
            ...optionalMutationFields,
        };
    }

    await baseMutation.performMutation(
        MutationEndpointConstants.BOOKING.EDIT,
        {
            bookingId,
            assetId,
            startDate,
            endDate,
            ...optionalMutationFields,
        },
        toastOnSuccess,
        onFailure,
    );
}