// @flow

import FeatureConfig from "../feature_config/FeatureConfig.js";
import * as EmailValidator from 'email-validator';

const MIN_SIMPLE_PASSWORD_LENGTH = 6;

export const isValidEmail = (email: string): boolean => {
    return EmailValidator.validate(email);
}

export const isPasswordValid = (password: string): [boolean, string] => {
    return FeatureConfig.shouldUseSimplePasswordValidation() ? isSimplePasswordValid(password) : isComplexPasswordValid(password);
}

const isSimplePasswordValid = (password: string): [boolean, string] => {
    return password.length >= MIN_SIMPLE_PASSWORD_LENGTH
        ? [true, "Password is valid"]
        : [false, "Password is too short"];
}

const isComplexPasswordValid = (password: string): [boolean, string] => {
    // TODO
    throw new Error("unimplemented");
}