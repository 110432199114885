// @flow
import { toast } from "react-toastify";
import axios from "axios";
import { getToastMessage, getToastConfig } from "../errors/errorUtils.js";
import ErrorType from "../errors/ErrorType.js";
import QueryEndpointConstants from "../constants/QueryEndpointConstants.js";

export const getUserQuery = async (): Promise<{
    email: string,
    firstName: ?string,
    middleName: ?string,
    lastName: ?string,
    phone: ?string,
    dob: Date,
    role: string,
    lastIp: ?string,
    lastLoginAt: ?string,
}> => {
    const response = await axios.get("/api" + QueryEndpointConstants.USER).catch((error) => {
        toast.error(getToastMessage(error), getToastConfig(ErrorType.USER_QUERY));
    });
    return response;
};