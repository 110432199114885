// @flow
import type { AssetTypeType } from "../types/AssetType.js";

import ViewAndEditBookingForm from "../forms/ViewAndEditBookingForm.react";

import React from "react";
import { Modal } from "react-bootstrap";

type Props = {
    shouldShow: boolean,
    bookingId: string,
    assetType: AssetTypeType,
    onBookingEdited: () => void,
    onBookingDeleted: () => void,
    onModalDismiss: () => void,
};


const ViewAndEditBookingModal = ({
    shouldShow,
    bookingId,
    assetType,
    onBookingEdited,
    onBookingDeleted,
    onModalDismiss,
}: Props): React.Node => {
    return <Modal
        show={shouldShow}
        onHide={onModalDismiss}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton>
            <Modal.Title>Booking Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ViewAndEditBookingForm bookingId={bookingId} assetType={assetType} onBookingEdited={onBookingEdited} onBookingDeleted={onBookingDeleted} />
        </Modal.Body>
    </Modal >


};

export default ViewAndEditBookingModal;