// @flow
import 'bootstrap/dist/css/bootstrap.css';

import type { UseQueryResult } from 'react-query';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useQuery } from 'react-query';
import logo from '../logo.png';
import '../App.css';
import React from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import LogOutButton from "../common_components/LogOutButton.react";
import QueryConstants from "../constants/QueryConstants";
import { getUserQuery } from "../queries/getUserQuery.js";
import FeatureConfig from "../feature_config/FeatureConfig.js";
import mapMod from "../mods/mapMods.js";
import { MOD_CONFIG } from "../types/Mods.js";
import PlaceholderMod from "../mods/PlaceholderMod.react";
import type { UserRoleType } from "../types/UserRole.js";
import BookingRootComponent from "../booking/BookingRootComponent.react";
import AssetRootComponent from "../assets/AssetRootComponent.react";
import LoggedInRoute from "../common_components/LoggedInRoute.react";
import PasswordChangeForm from "../forms/PasswordChangeForm.react";
import LoggedInHeader from "./headers/LoggedInHeader.react";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from "../common_components/ErrorFallback.react";
import { StaggeredGrid, StaggeredGridItem } from "react-staggered-grid";

type Props = {
    firstName: string,
    lastIp: ?string,
    lastLoginAt: ?string,
};

const MAX_PLACEHOLDERS = 3;

const getModComponents = (queryData: UseQueryResult<{
    email: string;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    phone: string | null;
    dob: Date;
    role: string;
    lastIp: string | null;
    lastLoginAt: string | null;
}, mixed>): Array<React.Node> => {
    if (queryData.isLoading) {
        return [];
    }

    const responseData = queryData.data;
    if (!responseData) {
        return [];
    }

    // $FlowFixMe no idea why flow doesn't like the access below
    const userData: { role: UserRoleType } = responseData?.data;
    if (!userData) {
        return [];
    }

    if (!(userData?.role)) {
        return [];
    }

    const enabledModNames = FeatureConfig.getModsForRoles().find((val) => val.role === userData.role);
    if (!enabledModNames) {
        return [];
    }

    const enabledMods = MOD_CONFIG.filter(mod => enabledModNames.mods.includes(mod.modName));
    return enabledMods.map(mod => mapMod(mod, userData.role));
};

const LaunchPad = (): React$MixedElement => {
    const queryData = useQuery(QueryConstants.USER_DATA, getUserQuery, { staleTime: QueryConstants.USER_DATA_STALE_TIME_MS });
    if (queryData.isLoading || queryData.isError || !(queryData.data)) {
        return <Spinner animation="border" />;
    }

    // $FlowFixMe
    const modComponents = getModComponents(queryData).filter(function (mod) {
        if (mod) return true;
        if (this.count >= MAX_PLACEHOLDERS) {
            return false;
        }
        this.count++;
        return true;
    }, { count: 0 });
    const nonNullModCount = modComponents.filter(modComp => !!modComp).length;

    if (nonNullModCount === 1) {
        // this is a hack, as we are assuming bookings is the actual enabled module
        // todo verify and redirect to correct module
        return <BookingRootComponent />
    }

    const placeHolderMod = <PlaceholderMod cardWidth="400px" />
    return <ErrorBoundary fallback={ErrorFallback}>
        <Container fluid className="d-flex justify-content-center">
            <div className="justify-content-center" style={{ width: '800px' }}>
                <StaggeredGrid
                    gridWidth={800} // width of each column
                    columns={2} // 0 would adjust columns
                    alignment={1} // 0 : start , 1 : center , 2 : end
                    horizontalGap={20} // horizontal gap between grid items
                    verticalGap={10}
                    fitHorizontalGap={true} // fit the gap with columnWidth
                >
                    {
                        modComponents.map((mod, index) => {
                            const modToRender = mod || placeHolderMod;
                            return <StaggeredGridItem key={"mod_" + index} index={index}>
                                {modToRender}
                            </StaggeredGridItem>
                        })
                    }
                </StaggeredGrid>
            </div>
        </Container>
    </ErrorBoundary>;
}

const HomePage = ({ firstName, lastIp, lastLoginAt }: Props): React$MixedElement => {
    return (
        <Router>
            <div className="App">
                <LoggedInHeader firstName={firstName} lastIp={lastIp} lastLoginAt={lastLoginAt} />
                <div className="pt-4 mt-5">
                    <Routes>
                        <Route element={<LoggedInRoute />}>
                            {/* $FlowFixMe modComponents typing*/}
                            <Route exact path="/" element={<LaunchPad />} />
                            <Route path="/bookings" element={<BookingRootComponent />} />
                            <Route path="/assets" element={<AssetRootComponent />} />
                        </Route>
                        <Route path="/password_change" element={<PasswordChangeForm />} />
                    </Routes>
                </div>
            </div>
        </Router >
    );
}

export default HomePage;
