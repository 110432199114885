// @flow

import React from 'react';
import airplaneImg from "../../img/airplane_icon.png"
import '../../App.css';
import SpinningAirplane from "../../common_components/SpinningAirplane.react";
import { Container, Row, Col } from 'react-bootstrap';

const LoggedOutHeader = (): React.Node => <header className="App-header">
    <Container fluid className="mt-5">
        <Row>
            <Col xd={12} className="d-flex justify-content-center">
                <div className="d-flex justify-content-center">
                    <SpinningAirplane />
                    <p style={{ position: 'absolute' }}>Welcome!</p>
                </div>
            </Col>

        </Row>
    </Container>
    <p className="mt-3 mb-1">
        Login or Register
    </p>
</header>;

export default LoggedOutHeader;