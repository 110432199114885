// @flow
const CookieConstants = {
    SESSION_DATA: 'session_data',
    SESSION_TOKEN: 'session_token',
    NORMAL_SESSION_TTL_HOURS: 1,
    LONG_SESSION_TTL_HOURS: 24 * 30, // 1 month
};

// TODO add a long lived cookie option when user clicked "remember me"
export const getBrandNewCookieExpiryDate = (isLongerSession: boolean): Date => {
    const now = new Date();
    now.setHours(
        now.getHours() +
        (isLongerSession ? CookieConstants.LONG_SESSION_TTL_HOURS : CookieConstants.NORMAL_SESSION_TTL_HOURS)
    );
    return now;
}

export default CookieConstants;