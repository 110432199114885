// @flow

import type { UserRoleType } from "../types/UserRole";

import CookieConstants from "../constants/CookieConstants.js";
import { useCookies } from "react-cookie";

export const extractDataFromCookie = (sessionDataCookie: any): ?{ firstName: string, token: string, lastIp?: ?string, lastLoginAt?: ?string, passwordChangeSoftRequested: ?boolean } => {
    const sessionToken = sessionDataCookie?.token;
    const firstName = sessionDataCookie?.firstName ?? 'User';
    const lastIp = sessionDataCookie?.lastIp;
    const lastLoginAt = sessionDataCookie?.lastLoginAt;
    const passwordChangeSoftRequested = sessionDataCookie?.passwordChangeSoftRequested ?? false;
    return sessionToken ? { token: sessionToken, firstName, lastIp, lastLoginAt, passwordChangeSoftRequested } : null;
};

export const useUserRoleFromSessionCookie = (): UserRoleType => {
    const [sessionDataCookie, _] = useCookies([CookieConstants.SESSION_DATA]);
    return sessionDataCookie?.session_data?.role;
}

export const clearCookies = (setSessionDataCookie: (name: string, value: any, options?: any) => void): void => {
    const date = new Date();
    // expire the cookie in 10s
    date.setSeconds(date.getSeconds() + 10);
    setSessionDataCookie(CookieConstants.SESSION_DATA, {}, { path: '/', expires: date });
};