// @flow
import './forms.css';

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import moment from "moment-timezone";
import CalendarConstants from "../constants/CalendarConstants.js";
import type { AssetTypeType } from "../types/AssetType.js";
import BookingAssetSelector from '../booking/BookingAssetSelector.react';
import { AssetType } from "../types/AssetType.js";
import * as bookingCreateMutation from "../mutations/bookingCreateMutation.js";
import UserSelector from "../users/UserSelector.react";
import { useIsUserAllowedToMutateBookingsForOthers } from "../utils/PermissionUtils";


type Props = {
    assetType: AssetTypeType,
    initialStartDate: Date,
    initialEndDate: Date,
    initialInstructorId: ?string,
    bookedAssetId: ?string,
    onBookingCreated: () => void;
}

const CreateBookingForm = (
    {
        assetType,
        initialStartDate,
        initialEndDate,
        initialInstructorId,
        bookedAssetId,
        onBookingCreated,
    }: Props): React$MixedElement => {
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [selectedAssetId, setSelectedAssetId] = useState(bookedAssetId);
    const [selectedInstructorId, setSelectedInstructorId] = useState(initialInstructorId);
    const defaultUserId: ?string = null;
    const [selectedUserId, setSelectedUserId] = useState(defaultUserId); // todo get from cookie the user id
    const isUserAllowedToMutateArbitraryBookings = useIsUserAllowedToMutateBookingsForOthers();

    const handleSubmit = async (e: any): Promise<void> => {
        e.preventDefault();

        await bookingCreateMutation.performMutation(
            startDate,
            endDate,
            assetType,
            selectedInstructorId,
            selectedAssetId,
            selectedUserId,
            onBookingCreated,
            () => { });
    };
    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={10}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='startDateLabel'
                                label="Start"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="datetime-local"
                                    value={moment(startDate).tz(CalendarConstants.TIMEZONE).format('YYYY-MM-DD HH:mm')}
                                    onChange={(e) => {
                                        const startDateStr = moment(e.target.value).tz(CalendarConstants.TIMEZONE).utc().format('YYYY-MM-DD HH:mm z');
                                        setStartDate(startDateStr);
                                    }}
                                    required
                                />
                            </Form.FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.FloatingLabel
                                controlId='endDateLabel'
                                label="End"
                                className='mb-3 form-label'
                            >
                                <Form.Control
                                    className="input-style"
                                    type="datetime-local"
                                    value={moment(endDate).tz(CalendarConstants.TIMEZONE).format('YYYY-MM-DD HH:mm')}
                                    onChange={(e) => {
                                        const endDateStr = moment(e.target.value).tz(CalendarConstants.TIMEZONE).utc().format('YYYY-MM-DD HH:mm z');
                                        setEndDate(endDateStr);
                                    }}
                                    required
                                />
                            </Form.FloatingLabel>
                        </Form.Group>

                        <BookingAssetSelector
                            assetType={AssetType.INSTRUCTOR}
                            selectedAssetId={selectedInstructorId}
                            setSelectedAssetId={setSelectedInstructorId}
                            variant="outline-secondary"
                            style={{ marginBottom: '16px' }}
                        />

                        {
                            assetType === AssetType.ROOM ?
                                <BookingAssetSelector
                                    assetType={AssetType.ROOM}
                                    selectedAssetId={selectedAssetId}
                                    setSelectedAssetId={setSelectedAssetId}
                                    variant="outline-secondary"
                                    // this will cause the selector to show up disabled
                                    hideIfNotEnoughAssets={false}
                                    style={{ marginBottom: '16px' }}
                                />
                                : // this ensures we show the aircraft selector for instructor assets too
                                <BookingAssetSelector
                                    assetType={AssetType.AIRCRAFT}
                                    selectedAssetId={selectedAssetId}
                                    setSelectedAssetId={setSelectedAssetId}
                                    variant="outline-secondary"
                                    // this will cause the selector to show up disabled
                                    hideIfNotEnoughAssets={false}
                                    style={{ marginBottom: '16px' }}
                                />
                        }

                        {isUserAllowedToMutateArbitraryBookings
                            ? <UserSelector
                                selectedUserId={selectedUserId}
                                setSelectedUserId={setSelectedUserId}
                                variant="outline-secondary" />
                            : null
                        }

                        <Button className="mt-3" variant="primary" type="submit" style={{ borderRadius: '20px', width: '100%', fontWeight: 'bold' }} onClick={handleSubmit} >
                            Create Booking
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container >
    );
};

export default CreateBookingForm;