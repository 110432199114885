// @flow
import type { CensoredAnnotatedBookingData } from "../types/CensoredAnnotatedBookingData.js";

import { toast } from "react-toastify";
import axios from "axios";
import { getToastMessage, getToastConfig } from "../errors/errorUtils.js";
import ErrorType from "../errors/ErrorType.js";
import QueryEndpointConstants from "../constants/QueryEndpointConstants.js";


const getSpecificBookingQuery = async (bookingId: string): Promise<CensoredAnnotatedBookingData> => {
    const bookingsReponse = await
        axios.get("/api" + QueryEndpointConstants.VIEW_BOOKING, {
            params: {
                bookingId
            }
        }).catch((error) => {
            toast.error(getToastMessage(error), getToastConfig(ErrorType.SPECIFIC_BOOKING_QUERY));
        });
    return bookingsReponse.data;
};

export default getSpecificBookingQuery;