// @flow

import type { AssetData } from "../types/AssetData.js";

import QueryConstants from "../constants/QueryConstants.js";
import { getAssetsForTypeQuery } from "../queries/getAssetsForTypeQuery.js";
import { AssetType } from "../types/AssetType.js";

export const getQueryConstant = (assetType: string): string => {
    switch (assetType) {
        case AssetType.AIRCRAFT:
            return QueryConstants.AIRCRAFT_ASSET_DATA;
        case AssetType.INSTRUCTOR:
            return QueryConstants.INSTRUCTOR_ASSET_DATA;
        case AssetType.ROOM:
            return QueryConstants.ROOM_ASSET_DATA;
        default:
            throw new Error("unrecognized asset");
    }
}

export const assetTypeToName = (assetType: string): string => {
    switch (assetType) {
        case 'AIRCRAFT':
            return "Aircraft";
        case 'INSTRUCTOR':
            return "Instructor";
        case 'ROOM':
            return 'Room';
        default:
            return 'Unknown';
    }
}

// $FlowFixMe
export const getQueryForType = (assetType: string) => {
    let queryConstant;
    switch (assetType) {
        case 'AIRCRAFT':
            queryConstant = QueryConstants.AIRCRAFT_ASSET_DATA;
            break;
        case 'INSTRUCTOR':
            queryConstant = QueryConstants.INSTRUCTOR_ASSET_DATA;
            break;
        case 'ROOM':
            queryConstant = QueryConstants.ROOM_ASSET_DATA;
            break;
        default:
            throw new Error("unimplemented");
    }

    return [queryConstant, () => getAssetsForTypeQuery(assetType)];
}

export const getAssetMap = (assets: Array<AssetData>): { [string]: string } => {
    const assetObjs = assets.map(asset => {
        const res: { [string]: string } = {};
        res[asset.assetId] = asset.assetName;
        return res;
    });

    return assetObjs.reduce((acc, assetObj) => ({ ...acc, ...assetObj }), {});
};


const AssetUtils = {
    getQueryConstant,
    getQueryForType,
    assetTypeToName,
    getAssetMap
};

export default AssetUtils;