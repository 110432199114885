// @flow
import type { BookingRelevanceType } from "../types/BookingRelevance.js";
import type { CensoredAnnotatedBookingData } from "../types/CensoredAnnotatedBookingData.js";
import type { AssetTypeType } from "../types/AssetType.js";
import { toast } from "react-toastify";
import axios from "axios";
import { getToastMessage, getToastConfig } from "../errors/errorUtils.js";
import ErrorType from "../errors/ErrorType.js";
import QueryEndpointConstants from "../constants/QueryEndpointConstants.js";


const getBookings = async (centralDate: Date, assetType: AssetTypeType, assetId: ?string, instructorId: ?string): Promise<Array<CensoredAnnotatedBookingData>> => {
    const bookingsResponse = await
        axios.get("/api" + QueryEndpointConstants.BOOKINGS, {
            params: {
                centralDate,
                assetType,
                assetId,
                instructorId,
            }
        }).catch((error) => {
            toast.error(getToastMessage(error), getToastConfig(ErrorType.ALL_BOOKINGS_QUERY));
        });
    return bookingsResponse?.data ?? [];
};

export default getBookings;