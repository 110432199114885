// @flow

import React from 'react';
import { Suspense, useEffect } from "react";
import type { AssetTypeType } from "../types/AssetType.js";
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { useQuery, useQueryClient } from "react-query";
import QueryConstants from "../constants/QueryConstants.js";
import { getAssetsForTypeQuery } from "../queries/getAssetsForTypeQuery.js";
import type { AssetData } from "../types/AssetData.js";
import type { ReactSetStateFunction } from "react";
import * as CalendarUtils from "../utils/CalendarUtils.js";
import AssetUtils from "../utils/AssetUtils.js";

type Props = {
    assetType: AssetTypeType,
    // will also disable if component is visible, but < 2 assets
    disabled?: boolean,
    selectedAssetId: ?string,
    setSelectedAssetId: ReactSetStateFunction<?string>,
    variant?: string,
    hideIfNotEnoughAssets?: boolean, // default true
    style?: Object,
};


// eslint-disable-next-line react/jsx-pascal-case
const _BookingAssetSelector = ({ assetType, disabled, style, setSelectedAssetId, selectedAssetId, variant, hideIfNotEnoughAssets = true }: Props): React.Node => {
    const queryClient = useQueryClient();
    const assetQuery = useQuery(
        AssetUtils.getQueryConstant(assetType),
        () => getAssetsForTypeQuery(assetType),
        {
            refetchOnMount: false,
            suspense: true,
            staleTime: QueryConstants.ASSET_DATA_STALE_TIME_MS,
        });
    // $FlowFixMe
    const assets: Array<AssetData> = assetQuery.data;

    useEffect(() => {
        if (assets && assets.length === 1 && selectedAssetId !== assets[0].assetId) {
            setSelectedAssetId(assets[0].assetId);
        }
    }, [assets, selectedAssetId]);

    if (assetQuery.error) {
        return <Spinner variant="danger" />;
    }

    if (assetQuery.isLoading) {
        return <Spinner variant="primary" />;
    }

    let shouldDisable = disabled;
    if (assets.length < 2) {
        shouldDisable = true;

        if (hideIfNotEnoughAssets) {
            return <></>;
        }
    }

    const defaultTitle = "Select " + AssetUtils.assetTypeToName(assetType);
    const renderSelectedAssetName = (selectedAssetId: string): string => {
        const selectedAsset = assets.find(a => a.assetId === selectedAssetId);
        if (!selectedAsset) {
            console.error("could not find an asset with the provied id");
            return selectedAssetId;
        }
        return selectedAsset.assetName;
    }

    const onSelect = (eventKey: string): void => {
        const newSelectedAssetId = eventKey === selectedAssetId ? null : eventKey;
        setSelectedAssetId(newSelectedAssetId);
        // this ensures any queries (e.g. calendar) depending on the selectedAsset are refetched
        // TODO: figure out how to use the cache for faster results
        // currently cache it shows empty results
        queryClient.invalidateQueries(CalendarUtils.getQueryConstantFromAsset(assetType, newSelectedAssetId, null, null));
    }

    return (
        <DropdownButton
            id={AssetUtils.assetTypeToName(assetType) + "_dropdown_selector"}
            className="mb-1"
            disabled={shouldDisable}
            title={selectedAssetId ? renderSelectedAssetName(selectedAssetId) : defaultTitle}
            size="lg"
            variant={variant ?? "secondary"}
            onSelect={onSelect}
            style={style} >
            {
                assets.map((asset =>
                    // we need a unique key prop because this is a list of items, but we won't really use "key"
                    <Dropdown.Item active={asset.assetId === selectedAssetId} key={AssetUtils.assetTypeToName(assetType) + "_" + asset.assetId} eventKey={asset.assetId}>{asset.assetName}</Dropdown.Item>
                ))
            }

        </DropdownButton >
    );
};

const BookingAssetSelector = (props: Props): React.Node => {
    return <Suspense fallback={<Spinner animation="border" />}>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_BookingAssetSelector {...props} />
    </Suspense>;
}

export default BookingAssetSelector;