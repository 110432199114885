// @flow
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css'

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import logo from './logo.png';
import './App.css';
import React from 'react';
import ServerConnectionDot from "./common_components/ServerConnectionDot.react"
import LoggedOutPage from './pages/LoggedOutPage.react';
import HomePage from "./pages/HomePage.react";
import WelcomePage from './pages/HomePage.react';
import { CookiesProvider, useCookies } from 'react-cookie';
import CookieConstants from './constants/CookieConstants.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { extractDataFromCookie } from "./utils/CookieUtils.js";
import { ToastContainer } from 'react-toastify';
import moment from "moment-timezone";
import FeatureConfig from "./feature_config/FeatureConfig";

function App(): React$Node {
  // globally set the default timezone
  moment.tz.setDefault(FeatureConfig.getDefaultTimezone());

  const [sessionDataCookie, _] = useCookies([CookieConstants.SESSION_DATA]);
  const parsedCookieData = extractDataFromCookie(sessionDataCookie?.session_data);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // TODO: investigate if we need this
        refetchOnWindowFocus: false,
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <div>
          {/* <div style={{ position: 'absolute' }} >
            <ServerConnectionDot />
          </div> */}
          {parsedCookieData ?
            <HomePage
              firstName={parsedCookieData.firstName}
              lastIp={parsedCookieData.lastIp}
              lastLoginAt={parsedCookieData.lastLoginAt} />
            : <LoggedOutPage />}
          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover

          />
        </div>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
