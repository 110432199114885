// @flow
import 'bootstrap/dist/css/bootstrap.css';
import '../forms/forms.css';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import '../App.css';
import React from 'react';
import { Row, Col, Container, Nav } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import CookieConstants from '../constants/CookieConstants.js'
import PostRegistrationMessage from "../forms/PostRegistrationMessage.react";
import LoggedOutRoute from "../common_components/LoggedOutRoute.react";
import LoggedOutHeader from "./headers/LoggedOutHeader.react";
import LoginForm from "../forms/LoginForm.react";
import RegistrationForm from "../forms/RegistrationForm.react";
import FeatureConfig from "../feature_config/FeatureConfig";

const LoggedOutPage = (): React$MixedElement => {
    const currentPath = window.location.pathname;
    const isRegistrationFlow = ["/registration", "/doneRegistration"].includes(currentPath);
    return (
        <Router>
            <div className="App">
                <LoggedOutHeader />

                <Container className="mt-5 mb-5 form-frame">
                    <Nav variant="underline" defaultActiveKey={isRegistrationFlow ? "/registration" : "/"}>
                        <Nav.Item>
                            <Nav.Link className='form-nav-link' eventKey="/" to="/" as={Link}>LOGIN</Nav.Link>
                        </Nav.Item>
                        {FeatureConfig.shouldDisableRegistration() ? null : <Nav.Item>
                            <Nav.Link className='form-nav-link' eventKey="/registration" to="/registration" as={Link}>REGISTER</Nav.Link>
                        </Nav.Item>}

                    </Nav>

                    <Routes>
                        <Route exact path="/" element={<LoginForm showTitle={false} />} />
                        <Route element={<LoggedOutRoute />}>
                            <Route path="/login" element={<LoginForm showTitle={false} />} />
                            {
                                FeatureConfig.shouldDisableRegistration()
                                    ? null
                                    : <Route path="/registration" element={<RegistrationForm showTitle={false} />} />
                            }
                            <Route path="/doneRegistration" element={<PostRegistrationMessage />} />
                            <Route path="*" element={<LoginForm showTitle={false} />} />
                        </Route>
                    </Routes>
                </Container>
            </div>
        </Router >
    );
}

export default LoggedOutPage;
