// @flow
import type { AssetTypeType } from "../types/AssetType.js";

import CreateBookingForm from "../forms/CreateBookingForm.react";

import React from "react";
import { Modal } from "react-bootstrap";

type Props = {
    shouldShow: boolean,
    assetType: AssetTypeType,
    initialStartDate: Date,
    initialEndDate: Date,
    initialInstructorId: ?string, // TODO reference the instructor
    bookedAssetId: ?string, // TODO create a proper asset/resource
    onBookingCreated: () => void;
    onCreateModalDismiss: () => void,
};


const CreateBookingModal = ({
    shouldShow,
    assetType,
    initialStartDate,
    initialEndDate,
    initialInstructorId,
    bookedAssetId,
    onBookingCreated,
    onCreateModalDismiss
}: Props): React.Node => {
    return <Modal
        show={shouldShow}
        onHide={onCreateModalDismiss}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton>
            <Modal.Title>Create a New Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CreateBookingForm assetType={assetType} bookedAssetId={bookedAssetId} initialStartDate={initialStartDate} initialEndDate={initialEndDate} initialInstructorId={initialInstructorId} onBookingCreated={onBookingCreated} />
        </Modal.Body>
    </Modal >


};

export default CreateBookingModal;